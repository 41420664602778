// import React from 'react';
// import { Row } from 'react-bootstrap';
// import excellenceimg from '../../images/Courses/excellence.png'
// import careeradv from '../../images/Courses/career-advancement.png'
// import handson from '../../images/Courses/hands-on-experience.png'

// const WhyCPC = () => {
//     return (
//         <>
//             <div><h2 className='text-center mt-3'>Why Take CPC Training from Thoughtflows?</h2></div>
//             <Row className='why-cpc' xs={1} md={3}>
//                 <div className='card col-lg-3 col-sm-12'>
//                     <div className='icon'>
//                         <img src={excellenceimg} />
//                     </div>
//                     <h3>CPC Excellence</h3>
//                     <p>Thoughtflows Academy offers top-notch CPC training - AAPC Certified course with a comprehensive curriculum and experienced instructors.</p>
//                 </div>
//                 <div className='card active col-lg-3 col-sm-12'>
//                     <div className='icon'>
//                         <img src={careeradv} />
//                     </div>
//                     <h3>Career Advancement</h3>
//                     <p className='text-light'>Completion of the CPC training from Thoughflows Academy can enhance your job prospects and help you advance in your career with affordable fees.</p>
//                 </div>
//                 <div className='card col-lg-3 col-sm-12'>
//                     <div className='icon'>
//                         <img src={handson} />
//                     </div>
//                     <h3>Hands-On Success</h3>
//                     <p>Our practical Mock question approach to learning can equip you with the necessary skills and knowledge to succeed as a Certified Professional Coder.</p>
//                 </div>
//             </Row>
//         </>
//     );
// }

// export default WhyCPC;





import React from 'react';
import { Row } from 'react-bootstrap';
import excellenceimg from '../../images/Courses/excellence.png';
import careeradv from '../../images/Courses/career-advancement.png';
import handson from '../../images/Courses/hands-on-experience.png';

const cardData = [
    {
        id: 1,
        image: excellenceimg,
        title: 'Expert Instructors',
        description: ' Experienced trainers who are CPC-certified provide personalized mentoring.',
        className: 'col-lg-3 col-sm-12',
        headingClass: 'text-black'
    },
    {
        id: 2,
        image: careeradv,
        title: 'Comprehensive Curriculum',
        description: 'Covers all aspects of the CPC exam — from medical terminology to compliance and ethics.',
        className: 'col-lg-3 col-sm-12 active',
        descriptionClass: 'text-light',
        headingClass: 'text-white'
    },
    {
        id: 3,
        image: handson,
        title: 'Flexible Learning Modes',
        description: ' Online and offline options tailored to your schedule.',
        className: 'col-lg-3 col-sm-12',
        headingClass: 'text-black'
    },


    {
        id: 4,
        image: handson,
        title: 'Practical Learning',
        description: ' Hands-on practice with real-world coding scenarios and case studies.',
        className: 'col-lg-3 col-sm-12',
        headingClass: 'text-black'
    },


    {
        id: 5,
        image: handson,
        title: 'High Success Rate',
        description: 'Our proven methods ensure high pass rates.',
        className: 'col-lg-3 col-sm-12',
        headingClass: 'text-black'
    },


    {
        id: 6,
        image: excellenceimg,
        title: 'Placement Support',
        description: 'Resume building, mock interviews, and job placement assistance.',
        className: 'col-lg-3 col-sm-12',
        headingClass: 'text-black'
    },
];

const WhyCPC = () => {
    return (
        <>
            <div className='px-4'><h2 className="text-center mt-3 ">Why Take CPC Training from Thoughtflows?</h2></div>
            <Row className="why-cpc px-4" xs={1} md={3}>
                {cardData.map((card) => (
                    <div key={card.id} className={`card ${card.className}`}>
                        <div className="icon" style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                            <img src={card.image} alt={card.title} />
                            <h3 className={card.headingClass}>{card.title}</h3>
                        </div>

                        <p className={card.descriptionClass}>{card.description}</p>
                    </div>
                ))}
            </Row>
        </>
    );
};

export default WhyCPC;

