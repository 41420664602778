import React from 'react'
import surgerybanner from '../../images/Web banner/Registered Health Information Administrator.jpg'

const RhiaBanner = () => {
    return (
        <>
            <div>
                <img src={surgerybanner} style={{ width: '100%', height: "350px", objectFit: "cover" }} alt="cpcbanner " />
            </div>
        </>
    )
}

export default RhiaBanner