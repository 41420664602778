import React from 'react';
import { Row } from 'react-bootstrap';
import excellenceimg from '../../images/Courses/excellence.png';
import careeradv from '../../images/Courses/career-advancement.png';
import handson from '../../images/Courses/hands-on-experience.png';

const cardData = [
    {
        "id": 1,
        "image": excellenceimg,
        "title": "Industry-Relevant Training",
        "description": "Our HIM course is designed to meet the needs of the healthcare industry, ensuring that you are job-ready.",
            "className": "col-lg-3 col-sm-12",
        "headingClass": "text-black"
    },
    {
        "id": 2,
        "image": careeradv,
"title": "Expert Trainers",
    "description": "Learn from experienced instructors with extensive backgrounds in healthcare coding and management.",
            "className": "col-lg-3 col-sm-12 active",
        "descriptionClass": "text-light",
        "headingClass": "text-white"
    },
    {
        "id": 3,
        "image": handson,
        "title": "Flexible Learning Options",
        "description": "Online and offline classes for maximum convenience.",
                "className": "col-lg-3 col-sm-12",
        "headingClass": "text-black"
    },
    {
        "id": 5,
        "image": careeradv,
        "title": "Comprehensive Curriculum",
        "description": "Our course covers all the essential modules, including coding systems, healthcare regulations, and data management.",        "className": "col-lg-3 col-sm-12 active",
        "descriptionClass": "text-light",
        "headingClass": "text-white"
    },
    {
        "id": 4,
        "image": handson,
        "title": "Job Assistance",
        "description": "Receive guidance and support in securing your dream job in HIM.",
        "className": "col-lg-3 col-sm-12",
        "headingClass": "text-black"
    },
    {
        "id": 5,
        "image": careeradv,
        "title": "Accredited Course",
        "description": " Study a program recognized by industry professionals, ensuring your qualifications hold value in the job market.",        "className": "col-lg-3 col-sm-12 active",
        "descriptionClass": "text-light",
        "headingClass": "text-white"
    },
];




const WhyChoose = () => {
    return (
        <>
            <div className='pt-10'>

                <div><h2 className="text-center mt-3 text-3xl font-semibold text-secondaryy">Why Thoughtflows is the Best Choice?</h2></div>
                <Row className="why-cpc" xs={1} md={3} lg={4}>
                    {cardData.map((card) => (
                        <div key={card.id} className={`card ${card.className}`}>
                            <div className="icon" style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                <img src={card.image} alt={card.title} />
                                <h3 className={card.headingClass}>{card.title}</h3>
                            </div>

                            <p className={card.descriptionClass}>{card.description}</p>
                        </div>
                    ))}
                </Row>
            </div>
        </>
    );
};

export default WhyChoose;

