import React from 'react'

const TrivandrumCertifications = () => {
    return (
        <div class="join-thoughtflows py-5 flex-col">
            <div class="join-thoughtflows-container">
                <div class="join-thoughtflows-text">
                    <h2>Accredited Medical Coders' Certifications</h2>
                    <p>Graduates from our academy are guaranteed to be well-prepared for certification tests because our curricula are in line with internationally recognized certifications like CPC and CCS. By confirming the knowledge gained throughout our training programs, these certificates improve employment opportunities.</p>
                </div>
            </div>
        </div>
    )
}

export default TrivandrumCertifications