import React from 'react'
import cpcbanner from '../../images/Web banner/Certified Professional Coder_-1.jpg'
function CICBanner() {
  return (
    <>
     <img src={cpcbanner} style={{ width: '100%', height: "", objectFit: "cover" }} alt="cpcbanner " />
    
    </>
  )
}

export default CICBanner