import React from 'react';
import cbeImg from '../images/Group636.png';
import kochiImg from '../images/Group637.png';
import thiruImg from '../images/Group638.png';
import hydImg from '../images/Group639.png';
import tiruvanImg from '../images/Group640.png';
import trichyImg from '../images/Group641.png';
import salemImg from '../images/salem.png';
import { Container, Row, Col } from 'react-bootstrap';

const Branches = () => {
    return ( 
        <div className="our-branches" style={{paddingLeft:'20px', paddingRight:'20px'}}>
            {/* <Container> */}
                <Row className="text-center mb-4">
                    <Col>
                        <h5>OUR BRANCHES</h5>
                        <h2>All Over South India Zone</h2>
                    </Col>
                </Row>
                <Row className="branches-list text-center justify-content-center">
                    <Col xs={6} sm={6} lg={2} className="mb-4">
                        <div style={{
                            transition: 'all 0.3s ease',
                            cursor: 'pointer'
                        }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.transform = 'scale(1.1) rotate(5deg)';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.transform = 'scale(1) rotate(0deg)';
                        }}>
                            <img src={cbeImg} alt="Coimbatore" className="img-fluid mb-2" />
                            <p>Coimbatore</p>
                        </div>
                    </Col>
                    <Col xs={6} sm={6} lg={2} className="mb-4">
                        <div style={{
                            transition: 'all 0.3s ease',
                            cursor: 'pointer'
                        }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.transform = 'scale(1.1) rotate(-5deg)';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.transform = 'scale(1) rotate(0deg)';
                        }}>
                            <img src={kochiImg} alt="Kochi" className="img-fluid mb-2" />
                            <p>Kochi</p>
                        </div>
                    </Col>
                    <Col xs={6} sm={6} lg={2} className="mb-4">
                        <div style={{
                            transition: 'all 0.3s ease',
                            cursor: 'pointer'
                        }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.transform = 'scale(1.1) rotate(5deg)';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.transform = 'scale(1) rotate(0deg)';
                        }}>
                            <img src={thiruImg} alt="Tirupati" className="img-fluid mb-2" />
                            <p>Tirupati</p>
                        </div>
                    </Col>
                    <Col xs={6} sm={6} lg={2} className="mb-4">
                        <div style={{
                            transition: 'all 0.3s ease',
                            cursor: 'pointer'
                        }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.transform = 'scale(1.1) rotate(-5deg)';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.transform = 'scale(1) rotate(0deg)';
                        }}>
                            <img src={hydImg} alt="Hyderabad" className="img-fluid mb-2" />
                            <p>Hyderabad</p>
                        </div>
                    </Col>
                    <Col xs={6} sm={6} lg={2} className="mb-4">
                        <div style={{
                            transition: 'all 0.3s ease',
                            cursor: 'pointer'
                        }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.transform = 'scale(1.1) rotate(5deg)';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.transform = 'scale(1) rotate(0deg)';
                        }}>
                            <img src={tiruvanImg} alt="Trivandrum" className="img-fluid mb-2" />
                            <p>Trivandrum</p>
                        </div>
                    </Col>
                    <Col xs={6} sm={6} lg={2} className="mb-4">
                        <div style={{
                            transition: 'all 0.3s ease',
                            cursor: 'pointer'
                        }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.transform = 'scale(1.1) rotate(-5deg)';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.transform = 'scale(1) rotate(0deg)';
                        }}>
                            <img src={trichyImg} alt="Trichy" className="img-fluid mb-2" />
                            <p>Trichy</p>
                        </div>
                    </Col>
                    {/* <Col xs={6} sm={4} lg={2} className="mb-4">
                        <img src={salemImg} alt="Salem" className="img-fluid mb-2" />
                        <p>Salem</p>
                    </Col> */}
                </Row>

            {/* </Container> */}
        </div>
    );
};

export default Branches;
