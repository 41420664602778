// import React from 'react';
// import { Row, Col } from 'react-bootstrap';
// import Arrow from '../../images/next.png';
// import coursebg from '../../images/coursebg.png'


// function WhyCIC() {
//   return (
//     <>
//     <Row className='cpc-study-material align-items-center '>
//         <Col lg={1}></Col>
//         <Col lg={10} style={{background:"#F1F0FE", padding:"50px"} }>
//             <div><h2 className='text-left mt-3'>Why you should take CIC training from thoughtflows academy?</h2></div>
//             <ul style={{ listStyleType: 'none', padding: 0 }}>
//                 <li>
//                     <img src={Arrow} alt="arrow" style={{ width: '15px', marginRight: '10px' }} />
//                     <strong>Specialized Focus:</strong> Thoughtflows Medical Coding Academy specializes in inpatient coding and the CIC certification, providing comprehensive training in this specific area.
//                 </li>
//                 <li>
//                     <img src={Arrow} alt="arrow" style={{ width: '15px', marginRight: '10px' }} />
//                    <strong> Experienced Instructors:</strong> Our instructors are highly experienced professionals with in-depth knowledge of inpatient coding and the CIC certification, offering valuable real-world expertise.
//                 </li>
//                 <li>
//                     <img src={Arrow} alt="arrow" style={{ width: '15px', marginRight: '10px' }} />
//                    <strong> Comprehensive Curriculum:</strong> Our CIC training program covers all necessary topics and competencies, regularly updated to align with coding guidelines and industry standards.
//                 </li>
//                 <li>
//                     <img src={Arrow} alt="arrow" style={{ width: '15px', marginRight: '10px' }} />
//                    <strong> Practical Application: </strong>Thoughtflows emphasizes hands-on training through case studies and coding exercises, allowing you to apply your knowledge in real-life scenarios.
//                 </li>
//                 <li>
//                     <img src={Arrow} alt="arrow" style={{ width: '15px', marginRight: '10px' }} />
//                    <strong> High Exam Success Rate: </strong>Our academy has a proven track record of high pass rates in the CIC certification, reflecting the effectiveness of our training approach.
//                 </li>
//                 <li>
//                     <img src={Arrow} alt="arrow" style={{ width: '15px', marginRight: '10px' }} />
//                     <strong>Flexible Learning Options:</strong> Thoughtflows offers flexible learning options, including online courses and self-paced study materials, catering to different schedules and learning styles.
//                 </li>
//                 <li>
//                     <img src={Arrow} alt="arrow" style={{ width: '15px', marginRight: '10px' }} />
//                     <strong>Career Development Support:</strong> In addition to exam preparation, we provide career development resources, job placement assistance, and networking opportunities to support your professional growth as a certified CIC.
//                 </li>
//             </ul>
//         </Col>
//         <Col lg={1}></Col>

//     </Row>

//     <Row className='cpc-study-material align-items-center'>
//                 <Col lg={1}></Col>
//                 <Col lg={5}>
//                     <div><h2 className='text-left mt-3'>CIC Exam Pattern & Books</h2></div>
//                     <ul style={{ listStyleType: 'none', padding: 0 }}>
//                         <li>
//                             <img src={Arrow} alt="arrow" style={{ width: '15px', marginRight: '10px' }} />
//                             ICD-10-CM code book (Current year), any publisher
//                         </li>
//                         <li>
//                             <img src={Arrow} alt="arrow" style={{ width: '15px', marginRight: '10px' }} />
//                             ICD-10-PCS code book (Current year), any publisher.
//                         </li>
//                         <li>
//                             <img src={Arrow} alt="arrow" style={{ width: '15px', marginRight: '10px' }} />
//                             40 multiple-choice questions and 7 inpatient cases fill-in-the-blank (proctored) question
//                         </li>
//                         <li>
//                             <img src={Arrow} alt="arrow" style={{ width: '15px', marginRight: '10px' }} />
//                             4 hours to complete the exam - Open Code Books (manuals)
//                         </li>
//                         <li>
//                             <img src={Arrow} alt="arrow" style={{ width: '15px', marginRight: '10px' }} />
//                             70% passing score required
//                         </li>

//                     </ul>
//                 </Col>
//                 <Col lg={1}></Col>
//                 <Col lg={4} className='course-details'>
//                     <div
//                         style={{
//                             border: '1px solid #ddd',
//                             borderRadius: '5px',
//                             backgroundImage: `url(${coursebg})`,
//                             backgroundRepeat: 'no-repeat',
//                             backgroundPosition: 'right top',
//                             backgroundSize: '27% 45%',
//                             padding: '40px',
//                             backgroundColor: '#e7f7f7'
//                         }}
//                     >
//                         <h3 className="mb-5" style={{ fontWeight: 'bold', color: '#2d3e50' }}>Course Details</h3>
//                         <div>
//                             <Row className="mb-2">
//                                 <Col><p><strong>Duration</strong></p></Col>
//                                 <Col className="text-start"><p>60 Days</p></Col>
//                             </Row>
//                             <Row className="mb-2">
//                                 <Col><p><strong>Class Type</strong></p></Col>
//                                 <Col className="text-start"><p>ONLINE/OFFLINE</p></Col>
//                             </Row>
//                             <Row className="">
//                                 <Col><p><strong>Batches</strong></p></Col>
//                                 <Col className="text-start"><p>Weekdays, Weekends</p></Col>
//                             </Row>
//                         </div>
//                     </div>
//                 </Col>
//                 <Col lg={1}></Col>
//             </Row>
//             <Row className='cpc-study-material-objective align-items-start' style={{background:"#179BA8",color:"white"}}>
//             <h2 style={{textAlign:"left"}}>Objectives of CIC Training</h2>
//                 <Col>

//                 <ul style={{ listStyleType: 'none', padding: 0 , textAlign:"justify",}}>
//                         <li style={{color:"white",listStyle:"circle"}}>

//                             Proficiency in reviewing medical records to abstract essential information for accurate inpatient coding.
//                         </li>
//                         <li style={{color:"white",listStyle:"circle"}}>
//                             Expertise in assigning precise ICD-10 codes for diagnoses and procedures in the inpatient setting.
//                         </li>
//                         <li style={{color:"white",listStyle:"circle"}} >
//                             Gain a comprehensive understanding of the latest regulations and issues in medical coding, compliance, and reimbursement, specifically focusing on MS-DRG and IPPS systems
//                         </li>
//                         <li style={{color:"white",listStyle:"circle"}} >
//                             Strong ability to adapt to coding and reimbursement rule changes, including updating the CDM, fees, and UB04 Field Locators.
//                         </li>

//                     </ul>
//                 </Col>
//                 <Col>
//                 <ul style={{ listStyleType: 'none', paddingLeft: "40px" , textAlign:"justify",}}>
//                         <li style={{color:"white",listStyle:"circle"}}>
//                         Solid understanding of anatomy, physiology, and medical terminology, vital for correct coding of facility services and diagnoses.
//                         </li>
//                         <li style={{color:"white" ,listStyle:"circle"}}>
//                         In-depth knowledge of different hospital types and departments, including teaching hospitals, ASCs, and outpatient departments.                        </li>
//                         <li style={{color:"white" ,listStyle:"circle"}}>
//                         Mastery in the business aspects of hospital inpatient services, differentiating between inpatient and outpatient services, payment methodologies, and compliance regulations.</li>

//                     </ul>
//                 </Col>

//             </Row>



// </>
//   )
// }

// export default WhyCIC





// import React from 'react';
// import { Row } from 'react-bootstrap';
// import excellenceimg from '../../images/Courses/excellence.png'
// import careeradv from '../../images/Courses/career-advancement.png'
// import handson from '../../images/Courses/hands-on-experience.png'

// const WhyCPC = () => {
//     return (
//         <>
//             <div><h2 className='text-center mt-3'>Why Take CPC Training from Thoughtflows?</h2></div>
//             <Row className='why-cpc' xs={1} md={3}>
//                 <div className='card col-lg-3 col-sm-12'>
//                     <div className='icon'>
//                         <img src={excellenceimg} />
//                     </div>
//                     <h3>CPC Excellence</h3>
//                     <p>Thoughtflows Academy offers top-notch CPC training - AAPC Certified course with a comprehensive curriculum and experienced instructors.</p>
//                 </div>
//                 <div className='card active col-lg-3 col-sm-12'>
//                     <div className='icon'>
//                         <img src={careeradv} />
//                     </div>
//                     <h3>Career Advancement</h3>
//                     <p className='text-light'>Completion of the CPC training from Thoughflows Academy can enhance your job prospects and help you advance in your career with affordable fees.</p>
//                 </div>
//                 <div className='card col-lg-3 col-sm-12'>
//                     <div className='icon'>
//                         <img src={handson} />
//                     </div>
//                     <h3>Hands-On Success</h3>
//                     <p>Our practical Mock question approach to learning can equip you with the necessary skills and knowledge to succeed as a Certified Professional Coder.</p>
//                 </div>
//             </Row>
//         </>
//     );
// }

// export default WhyCPC;





import React from 'react';
import { Row } from 'react-bootstrap';
import excellenceimg from '../../images/Courses/excellence.png';
import careeradv from '../../images/Courses/career-advancement.png';
import handson from '../../images/Courses/hands-on-experience.png';

const cardData = [
    {
        id: 1,
        image: excellenceimg,
        title: 'High Demand:',
        description: '  Hospitals and inpatient facilities are actively seeking <strong>CIC-certified coders</strong> to manage accurate billing and ensure regulatory compliance, making it a highly sought-after skill.',
        className: 'col-lg-3 col-sm-12',
        headingClass: 'text-black'
    },
    {
        id: 2,
        image: careeradv,
        title: 'Career Growth:',
        description: 'Achieving CIC certification boosts your career, providing opportunities for promotions, higher salaries, and job advancement within the healthcare sector.',
        className: 'col-lg-3 col-sm-12 active',
        descriptionClass: 'text-light',
        headingClass: 'text-white'
    },
    {
        id: 3,
        image: handson,
        title: 'Job Security:',
        description: '  Inpatient coding plays a crucial role in hospital revenue cycles, ensuring job stability as demand for skilled coders continues to rise',
        className: 'col-lg-3 col-sm-12',
        headingClass: 'text-black'
    },


    {
        id: 4,
        image: excellenceimg,
        title: 'Professional Credibility',
        description: 'CIC certification demonstrates your expertise and commitment to high standards in inpatient coding, increasing your professional credibility and trust with employers.',
        className: 'col-lg-3 col-sm-12',
        headingClass: 'text-black'
    },


    {
        id: 5,
        image: handson,
        title: 'Industry Recognition',
        description: 'The <strong>AAPC</strong>-awarded CIC certification is nationally recognized, enhancing your professional reputation across the healthcare industry.',
        className: 'col-lg-3 col-sm-12',
        headingClass: 'text-black'
    },


    // {
    //     id: 6,
    //     image: excellenceimg,
    //     title: 'Placement Support',
    //     description: 'Resume building, mock interviews, and job placement assistance.',
    //     className: 'col-lg-3 col-sm-12',
    //     headingClass: 'text-black'
    // },
];

const WhyCIC = () => {
    return (
        <>
            <div><h2 className="text-center px-4 mt-3">Why Become a Certified Inpatient Coder (CIC)?</h2></div>
            <Row className="why-cpc px-4" xs={1} md={3}>
                {cardData.map((card) => (
                    <div key={card.id} className={`card ${card.className}`}>
                        <div className="icon" style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                            <img src={card.image} alt={card.title} />
                            <h3 className={card.headingClass}>{card.title}</h3>
                        </div>

                        <p className={card.descriptionClass}
                            dangerouslySetInnerHTML={{ __html: card.description }}
                        />
                    </div>
                ))}
            </Row >
        </>
    );
};

export default WhyCIC;

