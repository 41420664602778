import React from 'react'
import parrex from "../../images/Branches/online/kochi.jpg"

function KochiFlex() {
  return (
    <>
    
    <div className="parallax-container">
      <div className="content-kochi" >
        <h2>
        Journey of Our Medical Coding Students
        </h2>
        <p >At Thoughtflows Medical Coding Academy, we take immense pride in the achievements of our students. The success stories of our past students are a testament to the effectiveness of our training programs and the impact of Thoughtflows Medical Coding Academy in shaping successful medical coding professionals.
        </p>
        <p>Through student testimonials and success stories, we celebrate the accomplishments of our alumni and showcase the diverse career paths that open up to them upon completion of their training. Their inspiring journeys serve as a source of motivation and encouragement for aspiring medical coders, highlighting the rewarding and fulfilling career prospects in the healthcare industry.
        </p>
        <p>
        The success stories of our students reflect the transformative power of our education and the opportunities that await our graduates. From overcoming challenges to achieving milestones, our alumni serve as role models, inspiring the next generation of medical coding professionals.
        </p>
     </div>
    </div>
    
    </>
  )
}

export default KochiFlex