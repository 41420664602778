import React from 'react'
import wow1 from "../../images/Branches/online/wow1.png"

function KochiOnline() {
  return (
   <>
   <div className='on-kochi'>

      <img  src={wow1}  alt='wow1'/>
      <div className='content-onkochi'>
       <h2>Career-Building Job Placement Assistance</h2>
       <p>
       At Thoughtflows Medical Coding Academy, we understand that securing a rewarding job after completing training is a top priority for our students. To support their career aspirations, we offer comprehensive job placement assistance. Our academy maintains strong partnerships with leading healthcare providers and coding organizations, giving our graduates an edge in the competitive job market.
       </p>
       <p>
       Our job placement assistance includes career counseling, resume preparation, interview coaching, and job search support. We guide our students in showcasing their skills and knowledge effectively to potential employers. We also organize job fairs and networking events, connecting our students with industry professionals and potential employers. Our commitment to the success of our students extends beyond the training period, ensuring that they embark on a fulfilling career as certified medical coders.
       </p>
      </div>
    </div>
   </>
  )
}

export default KochiOnline