import { useEffect } from "react";
import image from "./medic.jpg";
import postImage1 from '../images/Blog/medical-banner-with-doctor-working-laptop.jpg';

import "@fortawesome/fontawesome-free/css/all.min.css";
import { FaCircle } from "react-icons/fa";
export default function Blog1() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });    

    const toggles = document.querySelectorAll(".faq-toggle");

    const toggleFaq = (event) => {
      const faqElement = event.currentTarget.closest(".faq");
      faqElement.classList.toggle("active");
    };

    toggles.forEach((toggle) => {
      toggle.addEventListener("click", toggleFaq);
    });

    return () => {
      toggles.forEach((toggle) => {
        toggle.removeEventListener("click", toggleFaq);
      });
    };
  }, []);
  const topics = [
    { id: "introduction", title: "Introduction: What is Medical Coding?" },
    {
      id: "importance",
      title: "Why is Medical Coding Essential in Healthcare?",
    },
    {
      id: "how-it-works",
      title: "How Does Medical Coding Work? A Step-by-Step Guide",
    },
    { id: "types", title: "Types of Medical Coding Systems Explained" },
    {
      id: "skills",
      title: "Who Can Become a Medical Coder? Key Skills Needed",
    },
    { id: "careers", title: "Career Opportunities in Medical Coding" },
    {
      id: "certifications",
      title: "Certifications You Need to Succeed in Medical Coding",
    },
    { id: "who-needs", title: "Who Needs Medical Coding?" },
    { id: "how-to-become", title: "How to Become a Medical Coder?" },
    { id: "conclusion", title: "Conclusion: Your Future in Medical Coding" },
    { id: "faq", title: "FAQ" },
  ];
  const faqData = [
    {
      id: 1,
      ques: "What is medical coding?",
      answers:
        "Medical coding is the process of converting medical diagnoses, procedures, services, and equipment into standardized codes. These codes help healthcare providers, insurance companies, and other stakeholders communicate accurately and efficiently. It plays a key role in billing, reimbursement, and medical recordkeeping.",
      list_answer: [],
    },
    {
      id: 2,
      ques: "Why is medical coding important?",
      answers:
        "Medical coding is essential for accurate billing, regulatory compliance, and research. It ensures that healthcare providers are reimbursed for their services, maintains consistency in medical records, and allows for proper data analysis in healthcare research.",
      list_answer: [],
    },
    {
      id: 3,
      ques: "What are the different types of medical coding systems?",
      answers: "The three main coding systems are:",
      list_answer: [
        "ICD-10 (International Classification of Diseases): Used to code diagnoses and conditions.",
        "CPT (Current Procedural Terminology): Used to code medical procedures and services.",
        "HCPCS (Healthcare Common Procedure Coding System): Used for coding services, supplies, and equipment not covered by CPT codes.",
      ],
    },
    {
      id: 4,
      ques: "What are the qualifications required to become a medical coder?",
      answers:
        "To become a medical coder, you typically need to complete a training program that covers the basics of coding systems such as ICD-10, CPT, and HCPCS. Many coders pursue certification, such as the Certified Professional Coder (CPC) designation from the AAPC, which enhances job prospects.",
      list_answer: [],
    },
    {
      id: 5,
      ques: "How long does it take to become a certified medical coder?",
      answers:
        "The time it takes to become a certified medical coder varies. On average, it takes about 6 months to a year of training to gain the necessary knowledge and skills. Certification exams can be taken after completing a medical coding program.",
      list_answer: [],
    },
    {
      id: 6,
      ques: "Is medical coding a good career?",
      answers:
        "Yes, medical coding is a promising career with strong job growth. The demand for skilled coders continues to rise as the healthcare industry grows. Additionally, medical coding offers a flexible work environment, with many opportunities for remote work.",
      list_answer: [],
    },
    {
      id: 7,
      ques: "What skills are required for medical coding?",
      answers: "Some essential skills for medical coders include:",
      list_answer: [
        "Attention to detail",
        "Strong understanding of medical terminology and anatomy",
        "Proficiency in coding systems (ICD-10, CPT, HCPCS)",
        "Organizational and time management skills",
        "Ability to interpret medical records accurately",
      ],
    },
    {
      id: 8,
      ques: "Can medical coding be done from home?",
      answers:
        "Yes, many medical coding jobs offer the flexibility to work from home. Remote medical coding positions are available in hospitals, insurance companies, and other healthcare settings.",
      list_answer: [],
    },
    {
      id: 9,
      ques: "What is the difference between medical coding and medical billing?",
      answers:
        "While both medical coding and billing are part of the healthcare reimbursement process, they serve different functions. Medical coders convert healthcare services and diagnoses into standardized codes, while medical billers use those codes to create and submit invoices to insurance companies or patients for payment.",
      list_answer: [],
    },
    {
      id: 10,
      ques: "What are the job prospects for medical coders?",
      answers:
        "The job outlook for medical coders is very positive. According to the U.S. Bureau of Labor Statistics, the demand for medical records and health information technicians, including medical coders, is expected to grow much faster than average over the next decade.",
      list_answer: [],
    },
    {
      id: 11,
      ques: "What types of healthcare settings hire medical coders?",
      answers:
        "Medical coders are employed in a variety of healthcare settings, including:",
      list_answer: [
        "Hospitals",
        "Physician’s offices",
        "Clinics",
        "Insurance companies",
        "Medical billing companies",
        "Research organizations",
        "Government agencies",
      ],
    },
    {
      id: 12,
      ques: "How much does a medical coder earn?",
      answers:
        "The salary for medical coders varies based on factors like location, experience, and certification. On average, medical coders earn between ₹3,00,000 to ₹5,00,000 annually in India. In the U.S., the average salary for a certified medical coder is approximately $55,000 per year.",
      list_answer: [],
    },
    {
      id: 13,
      ques: "What are the certification options for medical coders?",
      answers:
        "There are several certification options for medical coders, including:",
      list_answer: [
        "Certified Professional Coder (CPC): Offered by AAPC, the CPC certification is one of the most widely recognized.",
        "Certified Coding Specialist (CCS): Offered by AHIMA, this certification is ideal for those who want to work in hospitals or larger healthcare settings.",
        "Certified Inpatient Coder (CIC): Specializes in coding for inpatient hospital care.",
        "Certified Outpatient Coder (COC): Focuses on coding for outpatient care and services.",
      ],
    },
    {
      id: 14,
      ques: "How do I stay updated with the latest coding guidelines?",
      answers:
        "The healthcare industry is constantly evolving, so it’s essential for medical coders to stay updated on changes to coding guidelines, regulations, and healthcare laws. Coders can do this by attending workshops, taking continuing education courses, and subscribing to resources like coding books and online platforms.",
      list_answer: [],
    },
    {
      id: 15,
      ques: "What are the challenges faced by medical coders?",
      answers: "Medical coders often face challenges such as:",
      list_answer: [
        "Keeping up with changing codes: Medical coding systems like ICD-10 and CPT are regularly updated, requiring coders to stay current.",
        "Handling complex cases: Some medical cases can be difficult to code, requiring thorough understanding and careful interpretation.",
        "Avoiding errors: Accuracy is essential in medical coding, as incorrect coding can result in claim denials or delays in reimbursement.",
      ],
    },
  ];

  return (
    <>
    <div
        style={{
          width: "auto",
          background: "linear-gradient(90deg,  #2E2C48 50%, #2E2C48 50%)",
          height: "auto",
          justifyContent: "space-around",
          fontSize: "40px",
        }}
        className="  lg:hidden "
      >
        <h1
          style={{
            padding: "50px",
            color: "white",
            fontSize: "35px",
            textAlign:"center"
          }}
        >
          What is Medical Coding? A Beginner's Guide to Success
        </h1>
        <div
          style={{
            background: "white",
            height: "auto",
            marginTop: "55px",
            marginRight: "85px",
            padding: "30px",
            marginBottom: "55px",
          }}
          className="w-full lg:w-1/2"
        >
          <p style={{ fontSize: "16px", fontWeight: "400" }}>
            Have you ever wondered how hospitals, insurance companies, and
            doctors manage massive amounts of patient data seamlessly? The
            answer lies in medical coding—a specialized process that ensures
            healthcare information is documented accurately and efficiently.
          </p>
          <p style={{ fontSize: "16px", fontWeight: "400" }}>
            Medical coding is the backbone of modern healthcare, linking
            diagnoses and treatments to insurance claims, research, and patient
            care improvement. Without it, the healthcare system would struggle
            to maintain accurate records and effective communication.
          </p>
          <p style={{ fontSize: "16px", fontWeight: "400" }}>
            In this blog, we’ll provide a comprehensive guide to medical
            coding—explaining what it is, why it's important, and how you can
            pursue a rewarding career in this field. Whether you’re a complete
            beginner or looking to enhance your knowledge, this guide will give
            you everything you need to know about the world of medical coding.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "auto",
          background: "linear-gradient(90deg,  #2E2C48 50%, #f0f0f0 50%)",
          height: "auto",
          // display: "none",
          justifyContent: "space-around",
          fontSize: "40px",
        }}
        className=" hidden lg:flex  "
      >
        <h1
          style={{
            width: "45%",
            marginTop: "155px",
            marginLeft: "80px",
            color: "white",
            fontSize: "35px",
          }}
        >
          What is Medical Coding? A Beginner's Guide to Success
        </h1>
        <div
          style={{
            background: "white",
            // width: "55%",
            height: "auto",
            marginTop: "55px",
            marginRight: "85px",
            padding: "30px",
            marginBottom: "55px",
          }}
          className="w-full lg:w-1/2"
        >
          <p style={{ fontSize: "16px", fontWeight: "400" }}>
            Have you ever wondered how hospitals, insurance companies, and
            doctors manage massive amounts of patient data seamlessly? The
            answer lies in medical coding—a specialized process that ensures
            healthcare information is documented accurately and efficiently.
          </p>
          <p style={{ fontSize: "16px", fontWeight: "400" }}>
            Medical coding is the backbone of modern healthcare, linking
            diagnoses and treatments to insurance claims, research, and patient
            care improvement. Without it, the healthcare system would struggle
            to maintain accurate records and effective communication.
          </p>
          <p style={{ fontSize: "16px", fontWeight: "400" }}>
            In this blog, we’ll provide a comprehensive guide to medical
            coding—explaining what it is, why it's important, and how you can
            pursue a rewarding career in this field. Whether you’re a complete
            beginner or looking to enhance your knowledge, this guide will give
            you everything you need to know about the world of medical coding.
          </p>
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row ">
        {/* Left Container - 75% Width */}
        <div className="w-full lg:w-3/4  bg-gray-100 p-4">
          <section id="introduction" className="max-w-4xl mx-auto md:px-6 py-12 -mb-24">
            {/* Heading */}
            <h1 className="text-3xl md:text-4xl font-bold text-center line-height-1 -pt-10 mt-20 text-gray-900 mb-6" style={{color:"#1d4971"}}>
              What is Medical Coding?
            </h1>
            <img src={postImage1} alt="postImage1" className="mx-auto block" />
            {/* Introductory Paragraph */}
            <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
              Medical coding is the process of converting medical diagnoses,
              procedures, services, and equipment into standardized codes. Think
              of it as a universal language that doctors, insurance companies,
              and healthcare providers use to communicate and ensure accurate
              billing, reporting, and patient care. .
            </p>
            <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
              When you visit a hospital, everything—from your symptoms to the
              doctor’s treatments—is documented. Medical coders take this
              information and translate it into specific codes using systems
              like ICD-10, CPT, and HCPCS.
            </p>
            <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
              A Simple Example: Let’s say a patient visits a doctor due to
              fever.
            </p>
            <ul className="text-lg text-gray-700 list-disc leading-relaxed text-justify mb-6">
              <li >
               <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">The doctor diagnoses the patient with a common viral fever and
               prescribes medication.</p> 
              </li>

              <li>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">A coder assigns the following codes:</p>
                <ul className="list-disc">
                  <li ><p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">ICD-10 Code: R50.9 (Fever, unspecified)</p></li>
                  <li>
                   <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6" >CPT Code: 99213 (Office visit for an established patient)</p> 
                  </li>
                </ul>
              </li>
            </ul>
            <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
              These codes help ensure the insurance company understands the
              situation and processes the claim correctly, allowing the clinic
              to receive payment for the services rendered.
            </p>
          </section>
          <section id="importance" className="max-w-4xl mx-auto md:px-6 py-12 -mb-24 ">
            {/* Heading */}
            <h1 className="text-3xl md:text-4xl font-bold text-center mt-20 text-gray-900 mb-6  "  style={{color:"#1d4971"}}>
              Why is Medical Coding Essential in Healthcare?
            </h1>
            {/* Introductory Paragraph */}
            <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
              Medical coding is the backbone of healthcare operations. Without
              it, the system would collapse under the weight of its complexity.
              Here are the top reasons why medical coding is critical:
            </p>
            <ol className="list-decimal ">
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Simplifies Healthcare Communication
                </b>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Medical coding provides a universal language that enables
                  healthcare providers, insurance companies, and researchers to
                  communicate effectively, ensuring everyone is on the same
                  page.
                </p>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Ensures Accurate Billing
                </b>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Insurance companies process claims based on the codes
                  provided. Proper coding is crucial; without , payments can be
                  delayed or denied, impacting the financial health of
                  healthcare providers.
                </p>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Enhances Patient Care
                </b>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Accurate coding creates a detailed record of a patient’s
                  medical history. This helps doctors and specialists track
                  treatments over time, allowing for better planning of future
                  care and improving patient outcomes.
                </p>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Supports Healthcare Analytics
                </b>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Governments and organizations rely on coding data to monitor
                  public health, track disease trends, and allocate resources.
                  This helps in making informed decisions and improving overall
                  healthcare systems.
                </p>
              </li>
            </ol>
          </section>
          <section id="how-it-works" className="max-w-4xl mx-auto md:px-6 py-12 -mb-24 ">
            {/* Heading */}
            <h1 className="text-3xl md:text-4xl font-bold text-center mt-20 text-gray-900 mb-6" style={{color:"#1d4971"}}>
              How Does Medical Coding Work? A Step-by-Step Guide
            </h1>
            {/* Introductory Paragraph */}
            <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
              Here’s how medical coding fits into the healthcare system:
            </p>
            <ol className="list-decimal ">
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  {" "}
                  Patient Visit
                </b>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  A patient visits a clinic or hospital and receives a diagnosis
                  or treatment.
                </p>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  {" "}
                  Documentation
                </b>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  The healthcare provider records the patient’s condition,
                  diagnosis, and any treatments or procedures performed during
                  the visit.
                </p>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Assigning Codes
                </b>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  A medical coder reviews the documentation and assigns the
                  appropriate codes for diagnoses and treatments using coding
                  manuals or software.
                </p>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  {" "}
                  Claim Submission
                </b>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  The assigned codes are submitted to insurance companies for
                  billing and reimbursement purposes.
                </p>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  {" "}
                  Payment Processing
                </b>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Insurance companies process the codes, verify the details, and
                  approve payment to the healthcare provider for services
                  rendered.
                </p>
              </li>
            </ol>
          </section>
          <section id="types" className="max-w-4xl mx-auto md:px-6 py-12 -mb-24  ">
            {/* Heading */}
            <h1 className="text-3xl md:text-4xl font-bold text-center mt-20 text-gray-900 mb-6" style={{color:"#1d4971"}}>
              Types of Medical Coding Systems Explained
            </h1>
            {/* Introductory Paragraph */}
            <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
              Medical coding relies on standardized systems. Let’s break them
              down:
            </p>
            <ol className="list-decimal">
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  ICD-10-CM (International Classification of Diseases - Clinical
                  Modification)
                </b>
                <ul className="list-disc">
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      <b>Purpose:</b> ICD-10-CM codes are used to document
                      diagnoses, symptoms, and conditions in patients. They are
                      essential for understanding the type of disease,
                      condition, or injury a patient has and form the basis for
                      treatment planning and billing.
                    </p>
                  </li>
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      {" "}
                      <b>Example: J02.9</b> - Acute pharyngitis (sore throat),
                      unspecified.<br></br> This code represents a diagnosis of
                      a sore throat caused by viral infection, though the exact
                      cause is not specified.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  CPT (Current Procedural Terminology)
                </b>
                <ul className="list-disc">
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      <b>Purpose:</b> CPT codes are used to document medical
                      procedures and services provided by healthcare providers.
                      These codes help in detailing the medical services
                      rendered, from routine check-ups to complex surgeries.
                    </p>
                  </li>
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      {" "}
                      <b>Example: 45378</b> - Colonoscopy, diagnostic.<br></br>
                      This code refers to a procedure where a physician examines
                      the colon using a camera to diagnose potential health
                      issues, such as polyps or cancer.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  HCPCS (Healthcare Common Procedure Coding System)
                </b>
                <ul className="list-disc">
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      <b>Purpose:</b> HCPCS codes are used to document services,
                      equipment, and supplies that are not covered by CPT codes.
                      This includes items like durable medical equipment,
                      ambulance services, and other medical supplies.
                    </p>
                  </li>
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      {" "}
                      <b>Example: E0100</b> - Standard crutches.<br></br>This
                      code is used for the purchase or rental of standard
                      crutches, ensuring proper billing for items not captured
                      under CPT codes.
                    </p>
                  </li>
                </ul>
              </li>
            </ol>
          </section>
          <section id="skills" className="max-w-4xl mx-auto md:px-6 py-12 -mb-24  ">
            {/* Heading */}
            <h1 className="text-3xl md:text-4xl font-bold text-center mt-20 text-gray-900 mb-6" style={{color:"#1d4971"}}>
              Who Can Become a Medical Coder? Key Skills Needed
            </h1>
            {/* Introductory Paragraph */}
            <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
              Medical coding is open to anyone with an interest in healthcare
              and attention to detail. Here’s what you’ll need to succeed:
            </p>
            <ol className="list-decimal ">
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  {" "}
                  Basic Knowledge of Medical Terms{" "}
                </b>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Understanding medical terminology is essential. While this may
                  seem daunting at first, don’t worry—this is part of your
                  training. Over time, you'll become comfortable with terms
                  related to anatomy, diseases, treatments, and procedures.
                </p>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  {" "}
                  Attention to Detail{" "}
                </b>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  In medical coding, even the smallest error can lead to
                  significant problems, such as incorrect billing, delayed
                  insurance claims, or legal issues. Coders must be precise and
                  thorough in their work to ensure accuracy.
                </p>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Analytical Thinking
                </b>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Coders need strong analytical skills to interpret medical
                  records correctly. They must review detailed medical
                  information and determine the appropriate codes based on
                  diagnoses, procedures, and treatments documented in patient
                  charts.
                </p>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  {" "}
                  Computer Skills
                </b>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Proficiency with coding software and electronic health records
                  (EHR) systems is critical. Coders use specialized software to
                  search for codes and input data, so a basic understanding of
                  these tools is essential for success.
                </p>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  {" "}
                  Time Management
                </b>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Medical coding is often time-sensitive, with strict deadlines
                  for claim submissions and billing cycles. Effective time
                  management ensures coders stay organized and meet deadlines
                  without compromising the quality of their work.
                </p>
              </li>
            </ol>
          </section>
          <section id="careers" className="max-w-4xl mx-auto md:px-6 py-12 -mb-24  ">
            {/* Heading */}
            <h1 className="text-3xl md:text-4xl mt-20 font-bold text-center text-gray-900 mb-6" style={{color:"#1d4971"}}>
              Career Opportunities in Medical Coding
            </h1>
            <ol className="list-decimal">
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Hospitals and Clinics
                </b>
                <ul className="list-disc">
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      <b>Overview:</b> Coders play a crucial role in managing
                      patient records and processing billing for healthcare
                      services. Hospitals and clinics depend on coders to ensure
                      accurate documentation for reimbursement.
                    </p>
                  </li>
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      {" "}
                      <b>Opportunities:</b> Inpatient and outpatient coding,
                      medical record management, billing departments.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Insurance Companies
                </b>
                <ul className="list-disc">
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      <b>Overview:</b> Insurance companies require coders to
                      review and process claims based on medical procedures,
                      diagnoses, and treatments. Coders help ensure claims are
                      accurate and meet compliance standards.
                    </p>
                  </li>
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      {" "}
                      <b>Opportunities:</b> Claims processing, medical review,
                      coding audits.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Remote Work
                </b>
                <ul className="list-disc">
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      <b>Overview:</b> Many organizations offer remote coding
                      positions, providing flexibility for coders who prefer to
                      work from home. This is a growing trend in the healthcare
                      industry.
                    </p>
                  </li>
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      {" "}
                      <b>Opportunities:</b>Telehealth coding, work-from-home
                      coding roles, freelance medical coding.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Specializations
                </b>
                <ul className="list-disc">
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      <b>Overview:</b>Coders can specialize in different medical
                      fields, such as radiology, oncology, or surgical coding.
                      Specialization often leads to higher-paying roles and
                      greater expertise in specific areas.
                    </p>
                  </li>
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      {" "}
                      <b>Opportunities:</b>Specialized coding roles in
                      radiology, cardiology, orthopedics, and more.
                    </p>
                  </li>
                </ul>
              </li>
            </ol>
          </section>
          <section id="certifications" className="max-w-4xl mx-auto md:px-6 py-12 -mb-24 ">
            {/* Heading */}
            <h1 className="text-3xl md:text-4xl font-bold text-center mt-20 text-gray-900 mb-6" style={{color:"#1d4971"}}>
              Certifications You Need to Succeed in Medical Coding
            </h1>
            {/* Introductory Paragraph */}
            <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
              Certifications validate your skills and make you stand out. Here
              are some of the top certifications:
            </p>
            <ol className="list-decimal">
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Certified Professional Coder (CPC)
                </b>
                <ul className="list-disc">
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      <b>Offered by:</b>AAPC
                    </p>
                  </li>
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      {" "}
                      <b>Overview:</b>The CPC is the most recognized and
                      sought-after certification for medical coders. It covers
                      outpatient settings and demonstrates proficiency in coding
                      various procedures using CPT, ICD-10-CM, and HCPCS codes.
                    </p>
                  </li>
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      {" "}
                      <b>Ideal for:</b>Those looking to work in outpatient
                      clinics, physician offices, or surgical centers.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Certified Coding Specialist (CCS)
                </b>
                <ul className="list-disc">
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      <b>Offered by:</b>AHIMA
                    </p>
                  </li>
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      {" "}
                      <b>Overview:</b>The CCS is ideal for coders working in
                      hospitals, focusing on inpatient and outpatient coding
                      using ICD-10-CM, CPT, and HCPCS codes. It’s highly
                      respected in the industry.
                    </p>
                  </li>
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      {" "}
                      <b>Ideal for:</b> Coders aiming to work in hospitals or
                      healthcare settings where both inpatient and outpatient
                      coding is required.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Certified Professional Coder (CPC)
                </b>
                <ul className="list-disc">
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      <b>Offered by:</b>AAPC
                    </p>
                  </li>
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      {" "}
                      <b>Overview:</b>The CIC focuses on inpatient coding,
                      covering hospital stays and procedures using ICD-10-CM and
                      ICD-10-PCS codes. It’s essential for coders specializing
                      in inpatient settings.
                    </p>
                  </li>
                  <li>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                      {" "}
                      <b>Ideal for:</b> Coders who wish to specialize in
                      inpatient coding, particularly in hospital environments.
                    </p>
                  </li>
                </ul>
              </li>
            </ol>
          </section>
          <section id="who-needs" className="max-w-4xl mx-auto md:px-6 py-12 -mb-24 ">
            {/* Heading */}
            <h1 className="text-3xl md:text-4xl font-bold mt-20 text-center text-gray-900 mb-6" style={{color:"#1d4971"}}>
              Who Needs Medical Coding?{" "}
            </h1>
            {/* Introductory Paragraph */}
            <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
              Medical coding is required across the entire healthcare system.
              Healthcare providers, insurance companies, and even government
              organizations depend on accurate medical coding for:{" "}
            </p>
            <ul className="list-disc ">
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  {" "}
                  Hospitals and Clinics:
                </b>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  To bill insurance companies and maintain patient records.
                </p>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  {" "}
                  Doctors and Specialists:{" "}
                </b>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  To document patient diagnoses and treatments, ensuring proper
                  reimbursement.
                </p>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Insurance Companies:
                </b>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  To evaluate and process claims.
                </p>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  {" "}
                  Researchers:
                </b>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  To analyze health trends and improve healthcare delivery.
                </p>
              </li>
            </ul>
            <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
              For individuals looking to work in healthcare administration or
              billing, medical coding is a highly desirable skill. Even
              healthcare providers like physicians and surgeons need basic
              coding knowledge to ensure proper documentation and billing.
            </p>
          </section>
          <section id="how-to-become" className="max-w-4xl mx-auto md:px-6 py-12 -mb-24 ">
            {/* Heading */}
            <h1 className="text-3xl md:text-4xl font-bold mt-20 text-center text-gray-900 mb-6" style={{color:"#1d4971"}}>
              How to Become a Medical Coder?{" "}
            </h1>
            {/* Introductory Paragraph */}
            <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
              Medical coding is an exciting and growing career, with a high
              demand for skilled professionals. If you’re interested in pursuing
              a career in medical coding, here’s how to get started:
            </p>
            <ol className="list-decimal ">
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  {" "}
                  Complete a Training Program
                </b>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Medical coding requires specialized knowledge of the coding
                  systems and healthcare documentation processes. Enroll in a
                  recognized training program that covers ICD-10, CPT, and HCPCS
                  coding systems. Many online and in-person programs offer
                  certification courses for aspiring medical coders.
                </p>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  {" "}
                  Get Certified{" "}
                </b>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Certification is highly recommended in the medical coding
                  field. The Certified Professional Coder (CPC) certification,
                  offered by the AAPC, is one of the most recognized credentials
                  for medical coders. It demonstrates your expertise and
                  increases your job prospects.{" "}
                </p>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Gain Experience
                </b>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  Many medical coding jobs require practical experience. You can
                  gain hands-on experience by applying for internships or
                  entry-level positions in healthcare settings. The more
                  experience you gain, the better you will become at assigning
                  codes and understanding complex medical documentation.{" "}
                </p>
              </li>
              <li>
                <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  {" "}
                  Stay Updated
                </b>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  The healthcare industry is constantly evolving, and so is
                  medical coding. Regular updates to coding systems and
                  healthcare regulations require coders to stay current with the
                  latest changes. Continuing education and training will help
                  you maintain your certification and keep your skills sharp.
                </p>
              </li>
            </ol>
          </section>
          <section id="conclusion" className="max-w-4xl mx-auto md:px-6 py-12 -mb-24  ">
            {/* Heading */}
            <h1 className="text-3xl md:text-4xl font-bold mt-20 text-center text-gray-900 mb-6" style={{color:"#1d4971"}}>
              Conclusion: Your Future in Medical Coding{" "}
            </h1>
            {/* Introductory Paragraph */}
            <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
              Medical coding is a career with unlimited potential. Whether
              you’re a student, a fresh graduate, or someone looking for a
              career change, this field offers stability, growth, and
              opportunities to work in healthcare without being a doctor or
              nurses.
            </p>
            <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
              <b> Ready to Begin Your Journey in Medical Coding?</b>{" "}
            </p>
            <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
              Embark on a rewarding career in the healthcare industry with
              <b> Thoughtflows Medical Coding Academy! 🌟</b>{" "}
            </p>
            <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
              Whether you're a beginner or looking to enhance your skills, we've
              got the tools and training to help you succeed.
            </p>
            <ol>
              <li>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  ✅ Comprehensive training programs
                </p>
              </li>
              <li>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  ✅ Expert mentorship
                </p>
              </li>
              <li>
                <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
                  ✅ Globally recognized certifications
                </p>
              </li>
            </ol>
            <b className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
              💡 Don’t wait to build your future—start today!
            </b>
            <p className="text-lg text-gray-700 leading-relaxed text-justify mb-6">
              ➡️ <b>Join Now </b>and take the first step toward an exciting
              career in medical coding!
            </p>
          </section>
          <section id="faq" className=" px-0 max-w-4xl mx-auto md:px-6 py-12 ">
            <div className="faq-container p-0 ">
              <h1 className="text-3xl md:text-4xl font-bold text-center line-height-1 -pt-10 mt-20 text-gray-900 mb-6" style={{color:"#1d4971"}}>Frequently Asked Questions</h1>
              {faqData.map((faq) => (
                <div key={faq.id} className="faq">
                  <h5 className="faq-title">{faq.ques}</h5>
                  <div className="faq-text">
                    <p>{faq.answers}</p>
                    {Array.isArray(faq.list_answer) &&
                      faq.list_answer.length > 0 && (
                        <ul>
                          {faq.list_answer.map((item, index) => (
                            <li
                              key={index}
                              className="flex flex-row space-x-2 items-center"
                            >
                              <FaCircle
                                className="fa-circle"
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  marginRight: "10px",
                                }}
                              />
                              {item}
                            </li>
                          ))}
                        </ul>
                      )}
                  </div>
                  <button className="faq-toggle">
                    <i className="fa-solid fa-chevron-down"></i>
                    <i className="fa-solid fa-times"></i>
                  </button>
                </div>
              ))}
            </div>
          </section>
        </div>
        {/* Right Container - 25% Width (Sticky) */}
        <div className=" sm:w-full  lg:w-1/4  bg-white p-4 shadow-lg h-fit px-5   lg:sticky lg:top-[115px] ">
          <h2 className="text-lg font-semibold" style={{color:"#1d4971"}} >Table of contents</h2>
          <ol className="list-decimal pl-5 space-y-2">
            {topics.map((topic) => (
              <li key={topic.id}>
                <a
                  href={`#${topic.id}`} style={{color:"#15aeb8"}}
                  className="text-blue-600 no-underline hover:underline"
                >
                  {topic.title}
                </a>
              </li>
            ))}
          </ol>
        </div>
      </div>
    </>
  );
}
