import React,{useEffect}from 'react'

const Career = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });   
     },[])
    return (
        <div>Career</div>
    )
}

export default Career