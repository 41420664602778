import React,{useEffect}from 'react'

import TirupathiBanner from './Tirupathi/TirupathiBanner'
import TirupathiExams from './Tirupathi/TirupathiExams'
import TirupathiContent from './Tirupathi/TirupathiContent'
import TirupathiCore from './Tirupathi/TirupathiCore'
import TirupathiSolidLayout from './Tirupathi/TirupathiSolidLayout'
import TirupathiGuiding from './Tirupathi/TirupathiGuiding'
import TirupathiModern from './Tirupathi/TirupathiModern'

const TirupathiBranch = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });   
 },[])
  return (
    <>
      <TirupathiBanner />
      <TirupathiContent />
      <TirupathiCore/>
      <TirupathiExams />
      <TirupathiSolidLayout/>
      <TirupathiModern/>
      <TirupathiGuiding/>
    </>
  )
}

export default TirupathiBranch