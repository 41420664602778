import React,{useEffect}from 'react'

import TrivandrumBanner from './Trivandrum/TrivandrumBanner'
import TrivandrumAbout from './Trivandrum/TrivandrumAbout'
import TiruvandrumChoice from './Trivandrum/TrivandrumChoice'
import TiruvandrumSticky from './Trivandrum/TiruvandrumSticky'
import TiruvandrumStat from './Trivandrum/TiruvandrumStat'
import TrivandrumOnline from './Trivandrum/TrivandrumOnline'
import TrivandrumOnline2 from './Trivandrum/TiruvandrumOnline2'
import TrivandrumStudent from './Trivandrum/TrivandrumStudent'
import TrivandrumAcademy from './Trivandrum/TrivandrumAcademy'
import TrivandrumCard from './Trivandrum/TrivandrumCard'
import TrivandrumCertifications from './Trivandrum/TrivandrumCertifications'
import TrivandrumCompanies from './Trivandrum/TrivandrumCompanies'

function Trivandrum() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });   
 },[])
  return (
    <>
      <TrivandrumBanner />
      <TrivandrumAbout />
      <TrivandrumAcademy />
      <TrivandrumCard />
      <TiruvandrumChoice />
      <TiruvandrumSticky />
      <TrivandrumCertifications />
      <TiruvandrumStat />
      <TrivandrumOnline />
      <TrivandrumOnline2 />
      <TrivandrumStudent />
      {/* <TrivandrumCompanies /> */}
    </>
  )
}

export default Trivandrum