import React from 'react'
// import branch from "../../images/Branches/image7 copy.png"

function TirupathiGuiding() {
  return (
    <>
    
    <div style={{background:"white",}} className="flex flex-col md:flex-row justify-between">

     <div style={{background:"#15aebb",padding:"30px"}} className='w-full '>
        
        <h2 style={{color:"white"}}>Modern Learning Environment</h2>
        <p  style={{color:"white", marginTop:"20px", textAlign:"justify"}} >Thoughtflows Medical Coding Academy is equipped with a modern and conducive learning environment that fosters a positive educational experience. Our extraordinary infrastructure, well-equipped classrooms, and advanced technology facilitate an immersive learning journey for our students. Our commitment to a modern learning environment also extends to the use of e-learning platforms, online resources, and interactive teaching methodologies. With access to digital learning materials, students can augment their knowledge and engage with course content at their own pace. The integration of technology in the learning process enriches the educational experience and equips students for a digital-driven healthcare landscape.</p>
        {/* <img src={branch} style={{width:"100px"}}/>  */}

     </div>
     <div style={{width:""}} className='w-full '>
     <p style={{padding:"30px", textAlign:"justify",wordSpacing:"4px", lineHeight:""}} ><span style={{fontWeight:"bold", }}>Thoughtflows Academy: Unlock Your Potential</span> Thoughtflows is not just a training institute; it is a place of empowerment. We strive to unlock the true potential of every student, helping them discover their strengths, overcome challenges, and achieve their career aspirations. Our academy's culture of empowerment instills confidence, resilience, and a growth mindset in our students, equipping them to navigate the complexities of the medical coding profession with ease. Through workshops, seminars, and personality development programs, we focus on developing students' soft skills, communication abilities, and professional etiquettes. Our holistic approach to education ensures that students are well-rounded professionals capable of excelling in any healthcare setting.</p>
     
     </div>
     
    </div>
    <div style={{background:"white",height:"", }} className="flex flex-col-reverse md:flex-row justify-between">
    <div className='w-full md:w-1/2'>
    <p style={{padding:"30px", textAlign:"justify",wordSpacing:"4px", lineHeight:""}}>
     <span style={{fontWeight:"bold"}}>Elevate Your Career Prospects</span> Thoughtflows Medical Coding Academy prepares students for industry-recognized certifications that are highly valued in the healthcare industry. By obtaining certifications from reputable organizations like AAPC (American Academy of Professional Coders), our graduates enhance their credentials and become more attractive to employers. Industry-recognized certifications validate students' expertise in medical coding and open doors to various career opportunities. Additionally, holding certifications demonstrates commitment to continuous learning and professional development, positioning our graduates as assets to any healthcare organization.</p>
     </div>
     <div style={{background:"#15aebb",width:"",padding:"25px"}} className='w-full md:w-1/2'>
        <h2 style={{color:"white"}}>Paving Your Way to Career Success</h2>
        <p  style={{color:"white", marginTop:"20px", textAlign:"justify"}}>Thoughtflows Medical Coding Academy is dedicated to not only providing quality education but also ensuring that our students have a smooth transition into their careers. Our comprehensive job placement assistance program connects students with potential employers and industry partners, thereby enhancing their employability. We organize job fairs, networking events, and interview preparation sessions to expose students to various career opportunities and foster professional growth. Our strong industry connections and reputation in the healthcare community ensure that employers recognize the value of a Thoughtflows education, making our graduates highly sought after in the job market.</p>
       {/* <img src={branch} style={{width:"100px"}} />  */}
       

     </div>
    
     
    </div>
   
    
    </>
  )
}

export default TirupathiGuiding 