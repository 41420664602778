import React,{useEffect,useContext,useState}from 'react'
import HomeBanner from './HomeBanner'
import GifSection from './GifSection'
import WelcomSection from './WelcomSection'
import PopularCourses from './PopularCourses'
import Branches from './Branches'
import Counters from './Counters'
import Coaching from './Coaching'
import GoogleRev from './GoogleRev'
import HomeBlog from './HomeBlog'
import Meta from '../Meta';
import FloatingIcons from '../FloatingIcons'
import Companies from './Companies'
import { PopupContext } from '../context/PopupContext';
import { motion } from 'framer-motion';
const Home = () => {
    const { isOpen, setIsOpen } = useContext(PopupContext);
    const [showPopup, setShowPopup] = useState(false); 
    useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });   

        const timer = setTimeout(() => {
            setShowPopup(true); // After 5 seconds, allow popup rendering
        }, 5000);

        return () => clearTimeout(timer); // Cleanup timer on unmount
    }, []);
     const togglePopup = () => {
         setIsOpen(!isOpen);
         setShowPopup(false);
     };
   
     // Handle form submission (just showing an alert for now)
     const handleSubmit = (e) => {
       e.preventDefault();
       alert("Form Submitted!");
       setIsOpen(false); // Close the popup after submission
     };
    return (
        <>
        <Meta title="Best Medical Coding Training Academy | Expert Coaching Institute" description="Get expert medical coding training at the best academy for comprehensive coaching. Start your career today!" />
        {/* Popup Form */}
        {showPopup && isOpen && (
          <div className="popup-overlay">
            <div className="popup">
              {/* Close Button at Top-Right Corner */}
              <div className="flex flex-row-reverse">
                <button className="close-bt" onClick={togglePopup}>
                  &times;
                </button>
              </div>
              <h2>Enquiry Form</h2>
              <form onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" required />

                <label htmlFor="phone">Phone Number:</label>
                <input type="text" id="phone" name="phone" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" required />

                <label htmlFor="course">Course:</label>
                <input type="text" id="course" name="course" required />

                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        )}
            <div style={{ overflow: 'hidden' }}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <HomeBanner />
              </motion.div>

              <motion.div
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.7 }}
              >
                <GifSection />
              </motion.div>

              <motion.div
                initial={{ x: -50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.7 }}
              >
                <WelcomSection />
              </motion.div>

              <motion.div
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.7, delay: 0.2 }}
              >
                <PopularCourses />
              </motion.div>

              <motion.div
                initial={{ scale: 0.8, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.7 }}
              >
                <Branches />
              </motion.div>

              <motion.div
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.7 }}
              >
                <Counters />
              </motion.div>

              <motion.div
                initial={{ x: 50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.7 }}
              >
                <Coaching />
              </motion.div>

              <motion.div
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.7 }}
              >
                <Companies/>
              </motion.div>

              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.7 }}
              >
                <GoogleRev />
              </motion.div>
            </div>
            {/* <FloatingIcons/> */}
        </>
    )
}

export default Home