import React from 'react'

const TrivandrumAcademy = () => {
    return (
        <div class="join-thoughtflows py-5 flex-col">
            <div class="join-thoughtflows-container">
                <div class="join-thoughtflows-text">
                    <h2>What Sets our Academy Apart in Trivandrum?</h2>
                    <p>At Thoughtflows Medical Coding Academy, we pride ourselves on several
                        distinguishing factors that set us apart as a premier institute for
                        medical coding training in Trivandrum. Our commitment to excellence,
                        student-centric approach, and industry partnerships create an
                        environment that fosters personal and professional growth. With a
                        focus on providing hands-on practical training and nurturing
                        industry-specific skills, we stand as a preferred choice for those
                        seeking a rewarding career in medical coding.</p>
                </div>
            </div>



        </div>
    )
}

export default TrivandrumAcademy