import React from "react";
import banner from "../images/AboutImg/tf23.jpg";
import { Container, Row, Col } from 'react-bootstrap';

function AboutBanner() {
  return (
    <section>

      <Row src={banner} style={{
        backgroundImage: `url(${banner})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'hidden',
        backgroundRepeat: 'no-repeat',
        height: '450px',
        alignItems: 'center'
      }}>

        <Col lg={1}></Col>

        <Col lg={12} className='cbe1-banner-lft'>
          <Col className='cbe1-banner-heading'>
            <h1 className=" mt-6 md:mt-0 md:-mb-36 text-center" style={{ fontSize: '', color:'#fff',paddingInline:"" }}>About Us</h1>
          </Col>
        </Col>
        <Col lg={10} className='cbe1-banner-lft'>
          <Col className='cbe1-banner-heading'>
            <h2 className="md:-mt-8 text-center" style={{ fontSize: '30px', color:'#fff',paddingInline:"20px" }}>Thoughtflows Medical Coding Academy is a leading institute specializing in expert medical coding training and certifications to advance healthcare careers.</h2>
          </Col>
        </Col>
        <Col lg={5} className='cbe1-banner-rht'>
        </Col>
        <Col lg={1}></Col>

      </Row>

      <Container fluid id="#we-are" className="abt-col p-0 justify-content-around align-items-center" style={{ height: '154px', width: "100%" }}>
        <Row className="w-100 h-100 text-center d-flex " style={{ background: "#15aebb", color: "white" }}>
          <Col className=" d-flex flex-column align-items-center col-1 front" style={{ width: '20%', justifyContent: "center" }}>

            <a href="#who-we" style={{ color: "white", textDecoration: 'none', }}><h4>Who We Are</h4></a>
            <hr className="custom-hr" />
          </Col>
          <Col className=" d-flex flex-column align-items-center col-1 front " style={{ width: '20%', justifyContent: "center" }}>

            <a href="#empower" style={{ color: "white", textDecoration: 'none', }}><h4>We Empower</h4></a>
            <hr className="custom-hr" />
          </Col>
          <Col className="  d-flex flex-column align-items-center col-1 front" style={{ width: '20%', justifyContent: "center" }}>

            <a href="#motto" style={{ color: "white", textDecoration: 'none', }}><h4>Our Motto</h4></a>
            <hr className="custom-hr" />
          </Col>
          <Col className=" d-flex flex-column align-items-center col-1 front " style={{ width: '20%', justifyContent: "center" }}>

            <a href="#vis" style={{ color: "white", textDecoration: 'none', }}><h4>Vision-Mission</h4></a>
            <hr className="custom-hr" />
          </Col>
          <Col className="  d-flex flex-column align-items-center col-1 front " style={{ width: '20%', justifyContent: "center" }}>

            <a href="#core" style={{ color: "white", textDecoration: 'none', }} ><h4>Our Core Values</h4></a>

            <hr className="custom-hr" />
          </Col>

        </Row>
      </Container>
    </section>
  );
}

export default AboutBanner;
