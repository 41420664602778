import React from 'react'
import cocbanner from '../../images/Web banner/Certified Professional Coder_.jpg'

const COCBanner = () => {
  return (
    <div>
        <img src={cocbanner} style={{ width: '100%', height: "350px", objectFit: "cover" }} alt="cpcbanner " />
      </div>
  )
}

export default COCBanner