import React from 'react'
import bannerImage from '../../images/homebannerbg.png'
import tirupathibanner from '../../images/Branches/brainstorm-meeting.png'
import { Container, Row } from 'react-bootstrap'
import CountUp from 'react-countup';
import { Col } from 'react-bootstrap'
import { Link } from "react-router-dom";
const TirupathiBanner = () => {
  return (
    <>
    <Row className='tirupathi-banner'
        style={{
            backgroundImage: `url(${bannerImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            overflow: 'hidden',
            backgroundRepeat: 'no-repeat',
            height: '550px',
            alignItems: 'center'
        }}
    >
        <Col lg={1}></Col>
        <Col lg={5} className='tirupathi-banner-lft'>
            {/* <Col className='cbe1-banner-dis'>
            <h6 className='disc'>20% Off</h6>
            <p className='cbe1-banner-lrn' style={{marginLeft:'15px', marginTop:'5px'}}>Learn from today</p>
        </Col> */}
            <Col className='tirupathi-banner-heading'>
                <h2 style={{ fontSize: '40px' }}>No.1 Leading Training Institute</h2>
                <h2 style={{ fontSize: '40px' }}>Medical Coding Academy in <span style={{ color: '#097D8A' }}>Tirupathi</span></h2>
            </Col>
            <Col>
                <p style={{ fontSize: '16px', marginTop: '20px' }}>Enroll in Thoughtflows Academy, the No.1 leading training institute in Tirupathi and unlock your talents in medical coding.</p>
            </Col>
            <Col className='cbe1-btn'>
                <Link to="/contact" className="box__link button-animation"> 
                    Register Now
                </Link>
            </Col>
        </Col>

        <Col lg={5} className='tirupathi-banner-rht'>
            {/* <img src={bannersmall} alt='bannersmall' className='bannersmall' /> */}
            <img src={tirupathibanner} alt='bannergif' className='bannergif' />
        </Col>
        <Col lg={1}></Col>
    </Row>
    <Container className='cbe1-counter'
        style={{
            background: '#fff',
            borderRadius: '80px',
            boxShadow: '0 3px 15px 0 rgba(0, 0, 0, 0.13)',
            marginTop: '-80px',
            padding: '20px 20px 10px 20px',
            zIndex: 2,
            position: 'relative',
        }}>
        <Row className="align-items-center">
            <Col lg={12}>
                <Row className="gy-4">
                    <Col xs={12} md={3} className="text-center">
                        {/* <img src={counter1img} alt="Training Icon" className="mb-3" /> */}
                        <p style={{ color: '#FFC100', fontWeight: '700', fontSize: '30px', margin: 0 }}>
                            <CountUp start={0} end={30000} separator="," />+
                        </p>
                        <p style={{ fontWeight: '500', fontSize: '20px', marginTop: 0 }}>Training</p>
                    </Col>
                    <Col xs={12} md={3} className="text-center">
                        {/* <img src={counter2img} alt="Placement Icon" className="mb-3" /> */}
                        <p style={{ color: '#F15BB5', fontWeight: '700', fontSize: '30px', margin: 0 }}>
                            <CountUp start={0} end={18000} separator="," />+
                        </p>
                        <p style={{ fontWeight: '500', fontSize: '20px', marginTop: 0 }}>Placement</p>
                    </Col>
                    <Col xs={12} md={3} className="text-center">
                        {/* <img src={counter3img} alt="Courses Icon" className="mb-3" /> */}
                        <p style={{ color: '#00BBFA', fontWeight: '700', fontSize: '30px', margin: 0 }}>
                            <CountUp start={0} end={20} separator="," />+
                        </p>
                        <p style={{ fontWeight: '500', fontSize: '20px', marginTop: 0 }}>Courses</p>
                    </Col>
                    <Col xs={12} md={3} className="text-center">
                        {/* <img src={counter4img} alt="Branch Icon" className="mb-3" /> */}
                        <p style={{ color: '#01F6D5', fontWeight: '700', fontSize: '30px', margin: 0 }}>
                            <CountUp start={0} end={11} separator="," />+
                        </p>
                        <p style={{ fontWeight: '500', fontSize: '20px', marginTop: 0 }}>Branches</p>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
</>
  )
}

export default TirupathiBanner