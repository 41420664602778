import React,{useEffect}from 'react'
import Cbe2Banner from './CBE-3/Cbe3Banner'
import Cbe2About from './CBE-3/Cbe3About'
import Cbe2Transform from './CBE-3/Cbe3Transform'
import Cbe2Exams from './CBE-3/Cbe3Exams'
import Cbe2Core from './CBE-3/Cbe3Core'
import Cbe2Contents from './CBE-3/Cbe3Contents'

const CbeBranch2 = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });   
 },[])
  return (
    <>
    <Cbe2Banner/>
    <Cbe2About/>
    <Cbe2Transform/>
    <Cbe2Exams/>
    <Cbe2Core/>
    <Cbe2Contents/>
    </>
  )
}

export default CbeBranch2