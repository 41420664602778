import React from 'react'
import EDbanner from '../../images/Web banner/Emergency Department.jpg'

function EdBanner() {
  return (
    <>
    
    <div>
      <img src={EDbanner} style={{ width: '100%', height: "350px", objectFit: "cover" }} alt="cpcbanner " />
    </div>
    </>
  )
}

export default EdBanner