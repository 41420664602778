import React, { useEffect } from 'react'
import HCCBanner from './HCC/HCCBanner'
import AboutHCC from './HCC/AboutHCC'
import HCCFeatures from './HCC/HCCFeatures'
import HCCObjective from './HCC/HCCObjective'
import HCCModule from './HCC/HCCModule'
import WhatHCC from './HCC/WhatHCC'
import WHYHCC from './HCC/WHYHCC'
import HCCStudyMaterial from './HCC/HCCStudyMaterial'
import HCCFAQ from './HCC/HCCFAQ'

const HCC = () => {
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'auto' });
    }, [])
  return (
    <>
      <HCCBanner />
      <WhatHCC />
      <WHYHCC />
      <HCCFeatures />
      <HCCModule />
      {/* <HCCStudyMaterial /> */}
      <HCCFAQ />

    </>
  )
}

export default HCC