import React from 'react';
import { Row } from 'react-bootstrap';
import excellenceimg from '../../images/Courses/excellence.png';
import careeradv from '../../images/Courses/career-advancement.png';
import handson from '../../images/Courses/hands-on-experience.png';

const cardData = [
    {
        id: 1,
        image: excellenceimg,
        title: 'High Demand',
        description: 'Skilled surgery coders are needed in hospitals and outpatient surgery centers.',
        className: 'col-lg-3 col-sm-12',
        headingClass: 'text-black'
    },
    {
        id: 2,
        image: careeradv,
        title: 'Better Pay',
        description: 'Specialized coding skills lead to higher salaries.',
        className: 'col-lg-3 col-sm-12 active',
        descriptionClass: 'text-light',
        headingClass: 'text-white'
    },
    {
        id: 3,
        image: handson,
        title: 'In-Depth Learning',
        description: 'Master CPT, ICD-10-CM, and HCPCS codes for surgical procedures.',
        className: 'col-lg-3 col-sm-12',
        headingClass: 'text-black'
    },
    {
        id: 4,
        image: handson,
        title: 'Compliance Focus',
        description: 'Ensure accurate coding and adherence to regulations (AAPC, CMS).',
        className: 'col-lg-3 col-sm-12',
        headingClass: 'text-black'
    },
    {
        id: 5,
        image: handson,
        title: 'Analytical Skills',
        description: 'Develop attention to detail by analyzing operative notes.',
        className: 'col-lg-3 col-sm-12',
        headingClass: 'text-black'
    },
    {
        id: 6,
        image: excellenceimg,
        title: 'Career Flexibility',
        description: 'Work in diverse settings or remotely.',
        className: 'col-lg-3 col-sm-12',
        headingClass: 'text-black'
    },
    {
        id: 7,
        image: excellenceimg,
        title: 'Credibility',
        description: 'Certification boosts your professional reputation.',
        className: 'col-lg-3 col-sm-12',
        headingClass: 'text-black'
    },
    {
        id: 8,
        image: excellenceimg,
        title: 'Continuous Growth',
        description: 'Opportunities to specialize in various surgical fields.',
        className: 'col-lg-3 col-sm-12',
        headingClass: 'text-black'
    }
];


const WhyTakeSurgery = () => {
    return (
        <>
            <div className='pt-10'>

                <div><h2 className="text-center mt-3 text-3xl font-semibold text-secondaryy">Why Take Surgery Coding Training?</h2></div>
                <Row className="why-cpc" xs={1} md={3} lg={4}>
                    {cardData.map((card) => (
                        <div key={card.id} className={`card ${card.className}`}>
                            <div className="icon" style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                <img src={card.image} alt={card.title} />
                                <h3 className={card.headingClass}>{card.title}</h3>
                            </div>

                            <p className={card.descriptionClass}>{card.description}</p>
                        </div>
                    ))}
                </Row>
            </div>
        </>
    );
};

export default WhyTakeSurgery;

