import React from 'react'
import surgerybanner from '../../images/Web banner/Certified Coding Specialist.jpg'

const CCSBanner = () => {
  return (
    <>
      <div>
        <img src={surgerybanner} style={{ width: '100%', height: "350px", objectFit: "cover" }} alt="cpcbanner " />
      </div>
    </>
  )
}

export default CCSBanner