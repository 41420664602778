import React from 'react'
import cpcbanner from '../../images/Web banner/IP DRUG.jpg'

const IpdrugBanner = () => {
  return (
    <>
    <img src={cpcbanner} style={{ width: '100%', height: "350px", objectFit: "cover" }} alt="cpcbanner " />
    </>
  )
}

export default IpdrugBanner