import React from 'react'
import cpcbanner from '../../images/Courses/cpcBanner.jpg'

const CPCBanner = () => {
    return (
        <div>
        <img src={cpcbanner} style={{ width: '100%', height: "350px", objectFit: "cover" }} alt="cpcbanner " />
      </div>
    )
}

export default CPCBanner