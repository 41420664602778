import React from 'react';
import { Row } from 'react-bootstrap';
import excellenceimg from '../../images/Courses/excellence.png';
import careeradv from '../../images/Courses/career-advancement.png';
import handson from '../../images/Courses/hands-on-experience.png';

const cardData = [
    {
        "id": 1,
        "image": excellenceimg, // Replace with the actual image if available
        "title": "Expert Trainers",
        "description": "Learn from AAPC-certified instructors with hands-on experience in radiology coding and current industry practices.",
        "className": "col-lg-3 col-sm-12",
        "headingClass": "text-black"
    },
    {
        "id": 2,
        "image": careeradv, // Replace with the actual image if available
        "title": "Interactive Learning",
        "description": "Engage in live classes featuring real-world case studies, PowerPoint presentations, and video tutorials for comprehensive understanding.",
        "className": "col-lg-3 col-sm-12 active",
        "descriptionClass": "text-light",
        "headingClass": "text-white"
    },
    {
        "id": 3,
        "image": handson, // Replace with the actual image if available
        "title": "Exclusive Content",
        "description": "Benefit from comprehensive study materials tailored to radiology coding, including live chart training and detailed guides.",
        "className": "col-lg-3 col-sm-12",
        "headingClass": "text-black"
    },
    {
        "id": 4,
        "image": handson, // Replace with the actual image if available
        "title": "Mock Exams & Assessments",
        "description": "Prepare thoroughly with chapter-wise assessments, full-length practice exams, and feedback to boost your confidence.",
        "className": "col-lg-3 col-sm-12",
        "headingClass": "text-black"
    },
    {
        "id": 5,
        "image": handson, // Replace with the actual image if available
        "title": "Placement Support",
        "description": "Get career-ready with our job placement assistance, including resume building, mock interviews, and connections to top healthcare organizations.",
        "className": "col-lg-3 col-sm-12",
        "headingClass": "text-black"
    }
];

const WhyChoose = () => {
    return (
        <>
            <div className='pt-10'>

                <div><h2 className="text-center mt-3 text-3xl font-semibold text-secondaryy">Why Choose Thoughtflows for Radiology Coding Training?</h2></div>
                <Row className="why-cpc" xs={1} md={3} lg={4}>
                    {cardData.map((card) => (
                        <div key={card.id} className={`card ${card.className}`}>
                            <div className="icon" style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                <img src={card.image} alt={card.title} />
                                <h3 className={card.headingClass}>{card.title}</h3>
                            </div>

                            <p className={card.descriptionClass}>{card.description}</p>
                        </div>
                    ))}
                </Row>
            </div>
        </>
    );
};

export default WhyChoose;

