import React from "react";
import branch from "../../images/Branches/image7 copy.png";
import { Col, Row } from "react-bootstrap";

function TrichyInter() {
  return (
    <>
      <div class="join-thoughtflows py-5 my-5 flex-col">
        <div class="join-thoughtflows-container">
          <div class="join-thoughtflows-text">
            <h2>Why Thoughtflows is Your Best Choice</h2>
            <p>Thoughtflows is the perfect place to kickstart or advance your medical coding career. Our academy provides a high-quality education through professional professors, a well-rounded curriculum, and modern facilities. We are dedicated to your success and provide job placement aid, reasonable pricing, and flexible learning alternatives. Our hands-on training will provide you with the industry skills you need, and our recognized certifications will help you advance in your profession. Choose Thoughtflows for a revolutionary educational experience that will enable you to excel in the dynamic world of medical coding.</p>
          </div>
        </div>

        

      </div>
    </>
  );
}

export default TrichyInter;
