import React from 'react'
import crcbanner from '../../images/Web banner/Certified Professional Biller.jpg'

const CRCBanner = () => {
  return (
    <>
    <div>
      <img src={crcbanner} style={{ width: '100%', height: "350px", objectFit: "cover" }} alt="cpcbanner " />
    </div>
    </>
  )
}

export default CRCBanner 