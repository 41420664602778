import React from "react";
import { Col, Row } from "react-bootstrap";

function TirupathiModern() {
  return (
    <>
      <Row className="interTirupathi" style={{paddingTop:"50px" ,paddingInline:"30px"}}>
        <h1 style={{textAlign:"center" , color:"#000"}}>Thought Flows</h1>
       <Col className="interTirupathiT1">
       <p>We at Thought Flows are dedicated to invent new technologies and methods in End to End RCM that makes ease to our trainees to learn new things in healthcare very quickly and also helps them to implement in live process.
       We provide services in medical coding, medical billing, medical auditing, medical scribing, medical transcription and Corporate training with high quality modules.</p>
       </Col>
      </Row>
    </>
  );
}

export default TirupathiModern;
