
export function TeamMember({ name, role, imageUrl }) {
  return (
    <div className="bg-white rounded-2xl p-6 shadow-lg transform transition-all duration-300 ease-in-out hover:shadow-2xl hover:-translate-y-2 hover:bg-gradient-to-br from-white to-gray-50 flex flex-col items-center">
      <div className="relative mb-4 group">
        <div className="w-32 h-32 rounded-full overflow-hidden ring-4 ring-transparent hover:ring-teal-400 transition-all duration-300">
          <img
            src={imageUrl || "https://via.placeholder.com/200x200"}
            alt={name}
            className="w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-110"
          />
        </div>
      </div>
      <h3 className="text-xl font-semibold text-gray-800 mb-1 transition-colors duration-300 group-hover:text-purple-700">{name}</h3>
      <p className="text-gray-500 mb-6 transition-colors duration-300 group-hover:text-purple-600">{role}</p>
      <div className="flex gap-4">
        {/* <a href="#" className="text-gray-400 hover:text-gray-600 transition-colors">
          <Facebook size={20} />
        </a>
        <a href="#" className="text-gray-400 hover:text-gray-600 transition-colors">
          <Twitter size={20} />
        </a>
        <a href="#" className="text-gray-400 hover:text-gray-600 transition-colors">
          <Instagram size={20} />
        </a>
        <a href="#" className="text-gray-400 hover:text-gray-600 transition-colors">
          <Linkedin size={20} />
        </a> */}
      </div>
    </div>
  )
}

