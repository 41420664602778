import React from 'react'
import Embanner from '../../images/Web banner/Evaluation & Mamagement.jpg'

function EMBanner() {
  return (
    <>
    <div>
      <img src={Embanner} style={{ width: '100%', height: "350px", objectFit: "cover" }} alt="cpcbanner " />
    </div></>
   
  )
}

export default EMBanner