import React from 'react'

const Cbe1JoinTf = () => {
    return (
        <>
            <div class="join-thoughtflows">
                <div class="join-thoughtflows-container">
                    <div class="join-thoughtflows-text">
                        <h2>Join Thoughtflows</h2>
                        <p>Thoughtflows proudly stands as the top medical coding training institute in Coimbatore. With our reputation for excellence, industry partnerships, and commitment to student success, we offer a learning environment that fosters growth, innovation, and professional development. Join us at Thoughtflows and be part of a leading institute dedicated to shaping the future of medical coding professionals.</p>
                        </div>
                </div>
            </div>
        </>
    )
}

export default Cbe1JoinTf