import React from 'react'
import cpmabanner from '../../images/Courses/Certified Professional Medical Auditor.jpg'

const CPMABanner = () => {
  return (
    <div>
      <img src={cpmabanner} style={{ width: '100%', height: "350px", objectFit: "cover" }} alt="cpcbanner " />
    </div>
  )
}

export default CPMABanner 