import React, { useState,useEffect } from 'react';
import contact from "../images/contact/6.jpg";
import map from "../images/contact/pinmap.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import Meta from '../Meta';
import { motion } from 'framer-motion';
function Contact() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });   
 },[])
  const [activeSection, setActiveSection] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    ph: '',
    qualification: '',
    message: '',
  });
  const [errors, setErrors] = useState({});

  const handleSectionClick = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };


  const locations = [
    {
      name: 'Saravanampatti',
      email: 'info@thoughtflows.in',
      phone: '+91 88075 10359 & +91 93845 76852 ',
      address: '172/2a first floor, promenade Tower, Sathy road saravanampatti, Coimbatore -641035',
    },
    {
      name: 'Hopes',
      email: 'info@thoughtflows.in',
      phone: '+91 9791974560 & +91 9384576852',
      address: '62A, first floor, Suriya Complex, Thaneerpandal Road, BR Puram, Coimbatore, Tamil Nadu 641004',
    },
    {
      name: 'Tirupathi',
      email: 'info@thoughtflows.in',
      phone: '+91 8790424835 & +91 9384576852',
      address: 'D.No.20-1-171/c1, first floor, upstairs MIOT diagnostic center, opposite Saibaba temple, tirumala bypass road, korlgunta tirupathi-517501',
    },
    {
      name: 'Hyderabad',
      email: 'info@thoughtflows.in',
      phone: '+91 8790751742 & +91 98045 76852',
      address: 'Example Address for Hyderabad, Telangana, India',
    },
    {
      name: 'Trivandrum',
      email: 'info@thoughtflows.in',
      phone: '070122 47525 & +91 9384576852',
      address: 'No.167, 1st Floor, Karimpanal Arcade, opp. to Padmanabhaswamy Temple, East Fort, Thiruvananthapuram, Kerala 695024',
    },
    {
      name: 'Kochi',
      email: 'info@thoughtflows.in',
      phone: '+91 9995790525 & +91 9384576852',
      address: '4 th floor, Vee Vee Tower, road, near Bhima Jewels, NH Bye Pass, Edappally, Kochi, Ernakulam, Kerala 682024.',
    },
    {
      name: 'Trichy',
      email: 'info@thoughtflows.in',
      phone: '+91 9384576852',
      address: '25,C-40,first floor,AMIL towers,3rd cross, Thillainagar east, Trichy,TamilNadu-620018',
    },
    {
      name: 'Salem',
      email: 'info@thoughtflows.in',
      phone: '+91 8148732017 & +91 9384576852',
      address: 'S Square Towers ,first floor, Omalur Main road Mamangam Salem tamil nadu',
    },
  ];

  const sectionStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(345deg, #88d4d9, rgb(240, 240, 240))',
    borderRadius: '8px',
    padding: '20px',
    margin: '10px',
    transition: '0.3s',
    cursor: 'pointer',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    minHeight: '200px',
    flex: '1 1 calc(50% - 20px)',
  };

  const activeStyle = {
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
    background: 'linear-gradient(90deg,  white 50%, white 50%)'
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: '' });
  };

  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!emailPattern.test(formData.email)) {
      newErrors.email = 'Email address is invalid';
    }
    if (!formData.ph) newErrors.ph = 'Phone number is required';
    const phonePattern = /^[0-9]{10}$/;
    if (!phonePattern.test(formData.ph)) {
      newErrors.ph = 'Phone number is invalid';
    }
    if (!formData.qualification) newErrors.qualification = 'Qualification is required';
    if (formData.message.length < 10) {
      newErrors.message = 'Message must be at least 10 characters long';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {

      console.log('Form submitted:', formData);

      setFormData({ name: '', email: '', message: '' ,ph:"",qualification:""});
      setErrors({});
    }
  };


  return (
    <>
    <Meta title="Contact Us - Thoughtflows Medical Coding Training  Academy" description="Thoughtflows Medical Coding Academy offers the highest quality education to help you succeed in your career, visit us today to learn more." />
    <motion.div 
      className="contact-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div 
        className="contact-header" 
        style={{ textAlign: 'center' }}
        initial={{  opacity: 1 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.7, delay: 0.2 }}
      >
        <h1 style={{ fontSize: '2.5em' }}>Get in touch</h1>
        <p style={{ fontSize: '16px', maxWidth: '700px', margin: 'auto' }}>
          Feel free to reach out to us using the options below, and our dedicated team will respond to your inquiries promptly.
        </p>
      </motion.div>

      <div className="contact-container" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', marginTop:'0px' }}>
        <motion.div 
          className="contact-form"
          style={{ flex: '1', marginLeft: '100px' }}
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.7, delay: 0.4 }}
        >
          <button className="contact-button" style={{ padding: '10px 60px', fontSize: '1em', marginBottom: '20px', fontFamily:'Poppins' }}>Contact Us</button>
          <h2>How can we help?</h2>
          <p>Have a question or feedback? Fill out the form below, and we'll get back to you as soon as possible.</p>
          <motion.form 
            onSubmit={handleSubmit}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <div style={{ marginBottom: '10px' }}>
              <input
                type="text"
                name="name"
                placeholder="Name"
                required
                style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
                value={formData.name}
                onChange={handleInputChange}
              />
              {errors.name && <span style={{ color: 'red', fontSize: '0.8em' }}>{errors.name}</span>}
            </div>
            <div style={{ marginBottom: '10px' }}>
              <input
                type="email"
                name="email"
                placeholder="Email address"
                required
                style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && <span style={{ color: 'red', fontSize: '0.8em' }}>{errors.email}</span>}
            </div>
            <div style={{ marginBottom: '10px' }}>
              <input
                type="tel"
                name="ph"
                placeholder="Phone"
                required
                style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
                value={formData.ph}
                onChange={handleInputChange}
              />
              {errors.ph && <span style={{ color: 'red', fontSize: '0.8em' }}>{errors.ph}</span>}
            </div>
            <div style={{ marginBottom: '10px' }}>
              <input
                type="text"
                name="qualification"
                placeholder="Qualification"
                required
                style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
                value={formData.qualification}
                onChange={handleInputChange}
              />
              {errors.qualification && <span style={{ color: 'red', fontSize: '0.8em' }}>{errors.qualification}</span>}
            </div>
            <div style={{ marginBottom: '10px' }}>
              <textarea
                name="message"
                placeholder="Message"
                rows="4"
                required
                style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
                value={formData.message}
                onChange={handleInputChange}
              ></textarea>
              {errors.message && <span style={{ color: 'red', fontSize: '0.8em' }}>{errors.message}</span>}
            </div>
            <motion.button 
              type="submit" 
              className="submit-button" 
              style={{ padding: '10px 20px', fontSize: '1em', borderRadius: '4px', backgroundColor: '#0D8F9C', color: '#fff', border: 'none' }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Submit
            </motion.button>
          </motion.form>
        </motion.div>
        <motion.div 
          className="contact-image" 
          style={{ flex: '1', marginRight: '100px' }}
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.7, delay: 0.4 }}
        >
          <img src={contact} alt='contact' style={{ width: '100%', borderRadius: '0px', }} />
        </motion.div>
      </div>

      <motion.div 
        style={{ display: 'flex', justifyContent: 'space-around', marginTop:'40px',alignItems:'center' }}
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.7 }}
      >
        <div>
          <h1 className='highligh' style={{ color: "#0a848f",paddingLeft:"20px", fontSize: "50PX", marginTop: "55px" }}>OUR BRANCHES</h1>
          <h2 style={{ marginTop: "20px" ,paddingLeft:"20px"}}>All Over South India Zone</h2>
        </div>
        <div style={{
          width: '450px',
          height: '400px',
          perspective: '1000px',
          overflow: 'hidden',
          padding:"25px"
        }}>
          <img 
            src={map} 
            className='Map-img' 
            alt="map" 
            style={{ 
              width: '100%',
              height: '100%',
              borderRadius: '0px',
              transition: 'transform 0.3s ease-out',
              transformStyle: 'preserve-3d'
            }}
            onMouseMove={(e) => {
              const rect = e.currentTarget.getBoundingClientRect();
              const x = (e.clientX - rect.left) / rect.width;
              const y = (e.clientY - rect.top) / rect.height;
              const tiltX = (y - 0.5) * 30;
              const tiltY = (x - 0.5) * -30;
              e.currentTarget.style.transform = `rotateX(${tiltX}deg) rotateY(${tiltY}deg) scale(1.1)`;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = 'none';
            }}
          />
        </div>
      </motion.div>

      <motion.div 
        className="flex flex-col flex-wrap md:flex-row"
        style={{paddingBottom: '45px', paddingRight: '20px', paddingLeft: '20px' }}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.7 }}
      >
        {locations.map((location, index) => (
          <motion.div
            key={location.name}
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: index * 0.1 }} 
            onClick={() => handleSectionClick(location.name)}
            style={{
              ...sectionStyle,
              ...(activeSection === location.name ? activeStyle : {}),
              position: 'relative',
              transition: 'all 0.4s ease',
              cursor: 'pointer',
              borderRadius: '15px',
              border: '1px solid rgba(255,255,255,0.18)'
            }}
            whileHover={{
              y: -10,
              rotate: 1,
              boxShadow: '0 15px 30px rgba(13, 143, 156, 0.3)',
              background: '#34aebb',
              borderColor: '#0D8F9C'
            }}
          >
            <h2 style={{ textAlign: 'center', marginBottom: '10px' }}>{location.name}</h2>

            {activeSection === location.name && (
              <motion.div 
                style={{ textAlign: 'center' }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <h5>Email: {location.email}</h5>
                <h5>Phone: {location.phone}</h5>
                <h5>Address: {location.address}</h5>
              </motion.div>
            )}

            <motion.button
              onClick={() => handleSectionClick(location.name)}
              style={{
                position: 'absolute',
                right: '10px',
                top: '20%',
                transform: 'translateY(-50%)',
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor: 'rgb(20, 174, 186)',
                border: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              whileHover={{ scale: 1.1, backgroundColor: '#0D8F9C' }}
              whileTap={{ scale: 0.95 }}
            >
              <FontAwesomeIcon icon={faArrowDown} style={{ fontSize: '15px', color: 'white' }} />
            </motion.button>
          </motion.div>
        ))}
      </motion.div>

    </motion.div>
    </>
  );
}

export default Contact;
