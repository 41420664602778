import React,{useEffect}from 'react'

import SalemBanner from './salem/SalemBanner'
import SalemExams from './salem/SalemExams'
import SalemContent from './salem/SalemContent'
import SalemCore from './salem/SalemCore'
import SalemSolidLayout from './salem/SalemSolidLayout'
import SalemGuiding from './salem/SalemGuiding'
import SalemModern from './salem/SalemModern'

const SalemBranch = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });   
 },[])
  return (
    <>
      <SalemBanner />
      <SalemContent />
      <SalemCore/>
      <SalemExams />
      <SalemSolidLayout/>
      <SalemModern/>
      <SalemGuiding/>
    </>
  )
}

export default SalemBranch