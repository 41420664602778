import React, { useEffect } from 'react'
import CDEIBanner from './CDEI/CDEIBanner'
import AboutCDEI from './CDEI/AboutCDEI'
import CDEIFeatures from './CDEI/CDEIFeatures'
import CDEIObjective from './CDEI/CDEIObjective'
import CDEIModule from './CDEI/CDEIModule'
import WhatCDEI from './CDEI/WhatCDEI'
import WHYCDEI from './CDEI/WHYCDEI'
import CDEIStudyMaterial from './CDEI/CDEIStudyMaterial'
import CDEIFAQ from './CDEI/CDEIFAQ'

const CDEI = () => {
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'auto' });
    }, [])
  return (
    <>
      <CDEIBanner />
      <WhatCDEI />
      <WHYCDEI />
      <CDEIFeatures />
      <CDEIModule />
      {/* <CDEIStudyMaterial /> */}
      <CDEIFAQ />

    </>
  )
}

export default CDEI