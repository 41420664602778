import React from 'react';
import emp2 from "../../images/course1.jpg";

function TrichyEmpower() {
  return (
    <>
      <div className="bg-gray-100 mt-12 pt-12 px-6 space-y-12">
        {/* Section 1 */}
        {/* <div className="max-w-6xl mx-auto text-center space-y-6">
          <h2 className="text-4xl font-bold text-primaryy">Mastering In-Demand Skills</h2>
          <p className="text-lg text-gray-700 leading-relaxed">
            At Thoughtflows Medical Coding Academy, we emphasize practical training as a crucial aspect of our teaching approach. We believe that hands-on experience is essential for students to develop proficiency and confidence in their coding abilities.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed">
            Our students engage in coding exercises, case studies, and coding simulations, allowing them to apply their theoretical knowledge to real-world scenarios. They practice coding on actual medical records, ensuring that they are well-prepared to handle the complexities of medical coding in professional settings. Through practical training, our students learn to navigate coding challenges, interpret medical documentation accurately, and code with precision. This practical approach equips them with the skills and expertise demanded by employers in the healthcare industry.
          </p>
        </div> */}

        {/* <div className="w-full">
          <img
            src={emp2}
            alt="Empowering Skills"
            className="w-full h-80 object-cover"
          />
        </div> */}

        {/* Section 2 */}
        {/* <div className="py-16 px-8">
          <div className="max-w-7xl mx-auto space-y-6">
            <h2 className="text-4xl font-extrabold text-center text-secondaryy">Recognized Certifications for Aspiring Medical Coders</h2>
            <p className="text-lg leading-relaxed">
              Thoughtflows Medical Coding Academy prepares students for industry-recognized certifications, such as those offered by AAPC (American Academy of Professional Coders). Earning these certifications is a testament to the student's knowledge and expertise in medical coding, enhancing their credibility and employability.
            </p>
            <p className="text-lg leading-relaxed">
              Our training program includes comprehensive exam preparation, ensuring that students are well-equipped to successfully clear the certification exams. Holding a recognized certification adds value to a medical coder's resume, opening doors to various career opportunities in hospitals, clinics, insurance companies, and coding organizations.
            </p>
          </div>
        </div> */}

        {/* Section 3 */}

      </div>


      <div className='empower3-Tri'>
        <div class="join-thoughtflows py-5 mb-5 flex-col">
          <div class="join-thoughtflows-container">
            <div class="join-thoughtflows-text">
              <h2>Empowering Your Career</h2>
              <p>At Thoughtflows Medical Coding Academy, we are not just educators; we are mentors dedicated to empowering our students to unlock their potential and pursue successful careers in medical coding. Our holistic approach to education aims to nurture both technical skills and professional development.We foster a supportive and inclusive learning environment that encourages students to excel and grow. We believe in instilling confidence in our students, motivating them to overcome challenges and achieve their goals.Our students graduate with not only technical competence but also essential soft skills, such as communication, problem-solving, and critical thinking. These qualities enable them to become valuable assets to healthcare organizations and contribute effectively to the healthcare industry.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TrichyEmpower;
