import React,{useEffect}from 'react'

import KochiBanner from "./Kochi/KochiBanner";
import KochiAbout from "./Kochi/KochiAbout";
import KochiChoice from "./Kochi/KochiChoice";
import KochiCore from "./Kochi/KochiCore";
import KochiFlex from "./Kochi/KochiFlex";
import KochiStick from "./Kochi/KochiStick";
import KochiStudents from "./Kochi/KochiStudents";
import KochiOnline from "./Kochi/KochiOnline";
import KochiOnline2 from "./Kochi/KochiOnline2";

function Kochi() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });   
 },[])
  return (
    <>
      <KochiBanner />
      <KochiAbout />
      <KochiChoice />
      <KochiCore />
      <KochiFlex />
      <KochiStick />
      <KochiOnline2 />
      <KochiOnline />
      <KochiStudents />
    </>
  );
}

export default Kochi;
