import { useEffect } from "react"
import { TeamMember } from "./TeamMember"
import group1 from "../images/Profile images/Group 1.png"
import group2 from "../images/Profile images/Group 2.png"
import group2copy from "../images/Profile images/Group 2 copy.png"
import group2copy2 from "../images/Profile images/Group 2 copy 2.png"
import group2copy3 from "../images/Profile images/Group 2 copy 3.png"
import group2copy4 from "../images/Profile images/Group 2 copy 4.png"
import group2copy5 from "../images/Profile images/Group 2 copy 5.png"
import group2copy6 from "../images/Profile images/Group 2 copy 6.png"
import group2copy8 from "../images/Profile images/Group 2 copy 8.png"
import group2copy9 from "../images/Profile images/Group 2 copy 9.png"
import group2copy10 from "../images/Profile images/Group 2 copy 10.png"
import group2copy11 from "../images/Profile images/Group 2 copy 11.png"
import group2copy12 from "../images/Profile images/Group 2 copy 12.png"
import group2copy13 from "../images/Profile images/Group 2 copy 13.png"
import group2copy14 from "../images/Profile images/Group 2 copy 14.png"  
import group2copy15 from "../images/Profile images/Group 2 copy 15.png"
import group2copy16 from "../images/Profile images/Group 2 copy 16.png"
import group2copy17 from "../images/Profile images/Group 2 copy 17.png"
import group2copy18 from "../images/Profile images/Group 2 copy 18.png"  
import group2copy19 from "../images/Profile images/Group 2 copy 19.png"
import group2copy20 from "../images/Profile images/Group 2 copy 20.png"
import group2copy21 from "../images/Profile images/Group 2 copy 21.png"
import group2copy22 from "../images/Profile images/Group 2 copy 22.png"
import group2copy23 from "../images/Profile images/Group 2 copy 23.png"  
import group2copy24 from "../images/Profile images/Group 2 copy 24.png"
import group2copy25 from "../images/Profile images/Group 2 copy 25.png"
import group2copy26 from "../images/Profile images/Group 2 copy 26.png"
import group2copy27 from "../images/Profile images/Group 2 copy 27.png"
import group2copy28 from "../images/Profile images/Group 2 copy 28.png"  
import group2copy29 from "../images/Profile images/Group 2 copy 29.png"          
import group2copy30 from "../images/Profile images/Group 2 copy 30.png"
import group2copy31 from "../images/Profile images/Group 2 copy 31.png"
import group2copy32 from "../images/Profile images/Group 2 copy 32.png"
import group2copy33 from "../images/Profile images/Group 2 copy 33.png"
import group2copy34 from "../images/Profile images/Group 2 copy 34.png"  
import group2copy35 from "../images/Profile images/Group 2 copy 35.png"
import group2copy36 from "../images/Profile images/Group 2 copy 36.png"
import group2copy37 from "../images/Profile images/Group 2 copy 37.png"
import group2copy38 from "../images/Profile images/Group 2 copy 38.png"  
import group2copy39 from "../images/Profile images/Group 2 copy 39.png"
import group2copy40 from "../images/Profile images/Group 2 copy 40.png"
import group2copy41 from "../images/Profile images/Group 2 copy 41.png"
import group2copy42 from "../images/Profile images/Group 2 copy 42.png"
import group2copy43 from "../images/Profile images/Group 2 copy 43.png"
import group2copy44 from "../images/Profile images/Group 2 copy 44.png"
import group2copy45 from "../images/Profile images/Group 2 copy 45.png"
import group2copy46 from "../images/Profile images/Group 2 copy 46.png"
import group2copy47 from "../images/Profile images/Group 2 copy 47.png"
import group2copy48 from "../images/Profile images/Group 2 copy 48.png"
import group2copy49 from "../images/Profile images/Group 2 copy 49.png"      
import group2copy50 from "../images/Profile images/Group 2 copy 50.png"
import group2copy51 from "../images/Profile images/Group 2 copy 51.png"
import group2copy52 from "../images/Profile images/Group 2 copy 52.png"
import group2copy53 from "../images/Profile images/Group 2 copy 53.png"
import group2copy54 from "../images/Profile images/Group 2 copy 54.png"
import group2copy55 from "../images/Profile images/Group 2 copy 55.png"  
import group2copy56 from "../images/Profile images/Group 2 copy 56.png"
import group2copy57 from "../images/Profile images/Group 2 copy 57.png"  
import group2copy58 from "../images/Profile images/Group 2 copy 58.png"
import group2copy59 from "../images/Profile images/Group 2 copy 59.png"
import group2copy60 from "../images/Profile images/Group 2 copy 60.png"
import group2copy61 from "../images/Profile images/Group 2 copy 61.png"
import group2copy62 from "../images/Profile images/Group 2 copy 62.png"
import group2copy63 from "../images/Profile images/Group 2 copy 63.png"
import group2copy64 from "../images/Profile images/Mittu - Franchise Partner - Kerala.jpeg"
import founder from "../images/Profile images/founder.png"
import ceo from "../images/Profile images/ceo.png"

const profileImages = [     
  founder, ceo, group2copy18, group2copy19, group2copy20, group2copy21, group2copy22, group2copy23, group2copy24, group2copy25, group2copy26, group2copy27,
  group2copy28, group2copy29, group2copy30, group2copy31, group2copy32, group2copy33, group2copy34, group2copy35, group2copy36, group2copy37,
  group2copy38, group2copy39, group2copy40, group2copy41, group2copy42, group2copy43, group2copy44, group2copy45, group2copy46, group2copy47,
  group2copy48, group2copy49, group2copy50, group2copy51, group2copy52, group2copy53, group2copy54, group2copy55, group2copy56, group2copy57,
  group2copy58, group2copy59, group2copy60, group2copy61, group2copy62, group2copy63
];

const teamMemberData = [
  { name: "Mr. BalaMurali", role: "FOUNDER & MD", image: founder },
  { name: "Ms. Bhanu", role: "FOUNDER & CEO", image: ceo },
  { name: "Logeshwaran ", role: "FRANCHISE PARTNER - SLM", image: group2copy28 },
  { name: "Arun Kumar Pandey ", role: "FRANCHISE PARTNER - DSNR", image: group2copy },
  { name: "Ashok Uppara  ", role: "FRANCHISE PARTNER - DSNR", image: group2 },
  { name: "Aswini  ", role: "FRANCHISE PARTNER - KL", image: group2copy8 },
  { name: "Mittu  ", role: "FRANCHISE PARTNER - KL", image: group2copy64 },
  { name: "thulasi  ", role: "GENERAL MANAGER", image: group2copy56 },
  { name: "Kartheeswari   ", role: "OPERATIONAL HEAD", image: group2copy21 },
  { name: "Jasmin   ", role: "REGIONAL HEAD", image: group2copy17 },
  { name: "Vidya   ", role: "REGIONAL HEAD", image: group2copy60 },
  { name: "Lokesh Babu ", role: "REGIONAL HEAD", image: group2copy29 },
  { name: "Keerthika ", role: "TRAINING MANAGER", image: group2copy24 },
  { name: "Manoj ", role: "TRAINING MANAGER", image: group2copy31},
  { name: "Mounika ", role: "TRAINING MANAGER", image: group2copy33 },
  { name: "Aleena susan JG ", role: "TRAINER", image: group2copy4 },
  { name: "Devi kiruba ", role: "TRAINER", image: group2copy12 },
  { name: "Elilarasi", role: "TRAINER", image: group2copy14 },
  { name: "Kalaimathi ", role: "TRAINER", image: group2copy19 },
  { name: "Priyadharshini ", role: "TRAINER", image: group2copy40 },
  { name: "Ram balaji  ", role: "TRAINER", image: group2copy41 },
  { name: "Vaanmathi  ", role: "TRAINER", image: group2copy57 },
  { name: "Veenadhari  ", role: "TRAINER", image: group2copy59 },
  { name: "Awanth", role: "BRANCH HEAD", image: group1 },
  { name: "Gayatri", role: "BRANCH HEAD", image: group2copy15 },
  { name: "Sruthi", role: "BRANCH HEAD", image: group2copy51 },
  { name: "Swetha", role: "TEAM LEAD", image: group2copy55 },
  { name: "Vaishna", role: "TEAM LEAD", image: group2copy58 },
  { name: "Karthika", role: "TEAM LEAD - SARAVANAMBATTI", image: group2copy23 },
  { name: "Pavithra", role: "TEAM LEAD", image: group2copy35 },
  { name: "Sindhuja", role: "TEAM LEAD - HYDERABAD", image: group2copy49 },
  { name: "Kalai Selvi", role: "TEAM LEAD - HOPES", image: group2copy18 },
  { name: "Anakha Suresh ", role: "TEAM LEAD - KL", image: group2copy3 },
  { name: "Sindhu Thakaiya ", role: "PROCESS COACH", image: group2copy48 },
  { name: "Ajai suriya ", role: "HR EXECUTIVE ", image: group2copy6 },
  { name: "Atchaya ", role: "HR EXECUTIVE ", image: group2copy9 },
  { name: "Dharshini  ", role: "HR EXECUTIVE ", image: group2copy13 },
  { name: "Gayathri  ", role: "HR EXECUTIVE ", image: group2copy16 },
  { name: "Gayathri  ", role: "HR EXECUTIVE ", image: group2copy16 },
  { name: "Kanchana  ", role: "HR EXECUTIVE ", image: group2copy20 },
  { name: "Kishore senthil  ", role: "HR EXECUTIVE ", image: group2copy26 },
  { name: "Peemuthannagari Supraja  ", role: "HR EXECUTIVE ", image: group2copy36 },
  { name: "Prabhu  ", role: "HR EXECUTIVE ", image: group2copy38 },
  { name: "Punitha  ", role: "HR EXECUTIVE ", image: group2copy42},
  { name: "Reshma Jenifar  ", role: "HR EXECUTIVE ", image: group2copy44},
  { name: "Sabnam banu  ", role: "HR EXECUTIVE ", image: group2copy45},
  { name: "Srujana Erothu   ", role: "HR EXECUTIVE ", image: group2copy50},
  { name: "Subashini  ", role: "HR EXECUTIVE ", image: group2copy52},
  { name: "Subiksha  ", role: "HR EXECUTIVE ", image: group2copy54},
  { name: "Suvarna  ", role: "HR EXECUTIVE ", image: group2copy53},
  { name: "Vignesh  ", role: "HR EXECUTIVE ", image: group2copy61},
  { name: "Akshaya  ", role: "TALENT ACQUISITION EXECUTIVE ", image: group2copy5},
  { name: "Arputhakumar  ", role: "TALENT ACQUISITION EXECUTIVE ", image: group2copy2},
  { name: "Madhumitha  ", role: "TALENT ACQUISITION EXECUTIVE ", image: group2copy30},
  { name: "Sanjay  ", role: "TALENT ACQUISITION EXECUTIVE ", image: group2copy46},
  { name: "Karthik  ", role: "DIGITAL MARKETING EXECUTIVE ", image: group2copy22},
  { name: "Kiruthika  ", role: "DIGITAL MARKETING EXECUTIVE ", image: group2copy25},
  { name: "Kowsikan  ", role: "DIGITAL MARKETING EXECUTIVE ", image: group2copy27},
  { name: "Mohamed Asiq   ", role: "DIGITAL MARKETING EXECUTIVE ", image: group2copy32},
  { name: "Ram kumar   ", role: "DIGITAL MARKETING EXECUTIVE ", image: group2copy43},
  { name: "Vishnupriya   ", role: "OFFICE ASSISTANT ", image: group2copy62},
  { name: "Deva Dharshini    ", role: "OFFICE ASSISTANT ", image: group2copy11},
  { name: "Babu    ", role: "OUTDOOR MARKETING EXECUTIVE ", image: group2copy10},
  { name: "Pradheesh    ", role: "OUTDOOR MARKETING EXECUTIVE ", image: group2copy39},
];

const teamStructure = [
  { role: "FOUNDER & MD / FOUNDER & CEO", count: 2 },
  { role: "FRANCHISE PARTNERS", count: 5 },
  { role: "GENERAL MANAGER", count: 1 },
  { role: "OPERATIONAL HEAD", count: 1 },
  { role: "REGIONAL HEAD", count: 3},
  { role: "TRAINING MANAGER", count: 3},
  { role: "TRAINER", count: 8},
  { role: "BRANCH HEAD", count: 3 },
  { role: "TEAM LEAD", count: 7 },
  { role: "PROCESS COACH", count: 1 },
  { role: "HR EXECUTIVE", count: 17 },
  { role: "TALENT ACQUISITION EXECUTIVE", count: 4 },
  { role: "DIGITAL MARKETING EXECUTIVE", count: 5 },
  { role: "OFFICE ASSISTANT", count: 2 },
  { role: "OUTDOOR MARKETING EXECUTIVE", count: 2 },
]

// Background colors for profile images
const bgColors = ["bg-pink-200", "bg-blue-200", "bg-gray-200", "bg-purple-200", "bg-green-200"]

function TeamHierarchy() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });   
     },[])
  let imageIndex = 0;  // Keep track of which images have been used

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      {/* Logo and Header */}
      <div className="mb-16 text-center">
        <h1>OUR TEAMS (HIERARCHY)</h1>
      </div>

      {/* Team Sections */}
      <div className="mx-auto max-w-7xl">
        <div className="space-y-16">
          {teamStructure.map((level, index) => (
            <div key={index} className="space-y-8">
              <h2>{level.role}</h2>
              <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {Array.from({ length: level.count }).map((_, memberIndex) => {
                  const currentImageIndex = imageIndex++;
                  const memberData = teamMemberData[currentImageIndex] || {
                    name: `Team Member ${currentImageIndex + 1}`,
                    role: level.role,
                    image: profileImages[currentImageIndex % profileImages.length]
                  };
                  
                  return (
                    <TeamMember
                      key={memberIndex}
                      name={memberData.name}
                      role={memberData.role}
                      imageUrl={memberData.image}
                    />
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TeamHierarchy

