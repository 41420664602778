import React from "react";

const RHITBenefits = () => {
 
  return (
    <section style={{backgroundColor:"#f9fafb"}} className="benefits-section px-3 py-10 ">
      <div className="container">
        <h2 className="text-3xl font-semibold m-4 mt-0 text-primaryy">
          About CCS-P Coders
        </h2>
        <p className="text-justify">
          CCS-P coders are highly skilled professionals responsible for
          reviewing patient medical records and assigning numeric codes to the
          diagnoses and procedures based on healthcare documentation. Their
          primary goal is to ensure accurate coding for services rendered by
          physicians, enabling proper insurance reimbursement while maintaining
          compliance with healthcare regulations
        </p>
      </div>
    </section>
  );
};

export default RHITBenefits;
