import React from 'react';
import { Row } from 'react-bootstrap';
import excellenceimg from '../../images/Courses/excellence.png';
import careeradv from '../../images/Courses/career-advancement.png';
import handson from '../../images/Courses/hands-on-experience.png';

const cardData = [
    {
        id: 1,
        image: excellenceimg,
        title: 'Expert Trainers',
        description: 'Learn from industry-certified instructors with extensive real-world experience in inpatient coding and reimbursement systems.',
        className: 'col-lg-3 col-sm-12',
        headingClass: 'text-black'
    },
    {
        id: 2,
        image: careeradv,
        title: 'Interactive Learning',
        description: 'Engage in live classes featuring case-based discussions, real-world scenarios, and interactive Q&A sessions.',
        className: 'col-lg-3 col-sm-12 active',
        descriptionClass: 'text-light',
        headingClass: 'text-white'
    },
    {
        id: 3,
        image: handson,
        title: 'Exclusive Content',
        description: 'Access comprehensive study materials designed specifically for mastering IPDRG, including coding manuals and practice guides.',
        className: 'col-lg-3 col-sm-12',
        headingClass: 'text-black'
    },
    {
        id: 4,
        image: handson,
        title: 'Mock Exams',
        description: 'Prepare thoroughly with chapter-wise assessments and full-length mock exams that mirror the actual certification test.',
        className: 'col-lg-3 col-sm-12',
        headingClass: 'text-black'
    },
    {
        id: 5,
        image: handson,
        title: 'Placement Support',
        description: 'Boost your career with resume-building workshops, mock interviews, and dedicated job placement assistance with top healthcare organizations.',
        className: 'col-lg-3 col-sm-12',
        headingClass: 'text-black'
    }
];


const WhyChooseIpDrug = () => {
    return (
        <>
            <div className='pt-10'>

                <div><h2 className="text-center mt-3 text-3xl font-semibold text-secondaryy px-3">Why Choose Thoughtflows for IPDRG Training?</h2></div>
                <Row className="why-cpc" xs={1} md={3} lg={4}>
                    {cardData.map((card) => (
                        <div key={card.id} className={`card ${card.className}`}>
                            <div className="icon" style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                <img src={card.image} alt={card.title} />
                                <h3 className={card.headingClass}>{card.title}</h3>
                            </div>

                            <p className={card.descriptionClass}>{card.description}</p>
                        </div>
                    ))}
                </Row>
            </div>
        </>
    );
};

export default WhyChooseIpDrug;

